import { ComponentType } from "react";
import { useAssets } from "./providers/assets";
import { PatternsAssetKind, PatternsKind } from "../types/design";
import { ThumbSelector, ThumbSelectorItem } from "./style/thumbSelector";
import { useEditor } from "./providers/editor";

const PatternSelector: ComponentType<Props> = ({ kind, onSelect, isSelect }) => {
    const {editor} = useEditor()
    const { assets } = useAssets()
    const items = assets.getPatternAssetList(kind)
    const handleClick = (assetId: string) => () => {
        onSelect(assetId)
    }
    return (
        <>
            <ThumbSelector>
                {items!.map(item => (
                    <ThumbSelectorItem
                        key={item.id}
                        onClick={handleClick(item.id)}
                        // selected={item.id === editor.design!.getPatternAssetId(kind)}
                        selected={isSelect(item.id)}
                        thumb={item.thumb}
                    />
                ))}
            </ThumbSelector>
        </>
    )
}

export default PatternSelector

interface Props {
    kind: PatternsAssetKind
    onSelect: (assetId:string) => any
    isSelect: (assetId:string) => boolean
}
