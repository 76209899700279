import { Loader } from "./Loader";

export interface Layer extends Loader {
    readonly width: number
    readonly height: number
    draw(dst: HTMLCanvasElement): Promise<void>
}

export const NullLayer = new (class implements Layer {
    readonly width: number;
    readonly height: number;

    constructor(width:number, height: number) {
        this.width = width
        this.height = height
    }

    async draw(dst: HTMLCanvasElement): Promise<void> {
    }
    load(): Promise<void>[] {
        return []
    }
})(0, 0)
