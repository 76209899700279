import ReactDOM from 'react-dom'
import { Assets } from "../../types/asset";
import { Design } from "../../types/design";
import { Angle, ManType, Snapshot } from "../../types/editor";
import { Server } from '../../types/server';
import Preview from "./Preview";

export const takeSnapshots = async (
    design: Design,
    assets: Assets,
    markingImageServer: Server,
    designServer: Server,
    preRenderPreview: () => Promise<any>
): Promise<Snapshot[]> => {
    const f = (m: ManType, a: Angle): Promise<Snapshot> => {
        return new Promise<Snapshot>(resolve => {
            const handleDraw = (canvas: HTMLCanvasElement) => {
                canvas.toBlob(async b => {
                    if (b == null) {
                        return
                    }
                    const mediaId = await designServer.saveDesignSnapshotMedia(b)
                    resolve({ mediaId, manType: m, angle: a })
                }, "image/png")
            }
            const c = <Preview manType={m} angle={a} assets={assets} design={design} markingImageServer={markingImageServer} className="" onDraw={handleDraw} preRender={preRenderPreview}/>
            const elm = document.createElement('div')
            ReactDOM.render(c, elm)
        })
    }
    const plist: Promise<Snapshot>[] = [
        f('man01', 'front'),
        f('man01', 'back'),
    ]
    return Promise.all(plist)
}
