import { useEditor } from "./providers/editor"
import MarkingImageInput from "./MarkingImageInput"
import MarkingPlacementSelector from "./MarkingPlacementSelector"
import MarkingTextInput from "./MarkingTextInput"
import { ControlBlock } from "./style/editorControl"

const Marking = () => {
    const { editor } = useEditor()
    const renderContent = () => {
        if (editor.selectedMarkingPlacement === undefined) {
            return <MarkingPlacementSelector />
        } else if (editor.selectedMarkingType === 'text') {
            return <MarkingTextInput />
        } else if (editor.selectedMarkingType === 'image') {
            return <MarkingImageInput />
        }
        return <></>
    }
    return (
        <ControlBlock
            title="マーキング配置"
            titleNumber="01"
            disabled={editor.isSubcategoryDisabled("marking")}
            selected={editor.selectedSubCategories["shirt"] === "marking"}
        >
            {renderContent()}
        </ControlBlock>
    )
}

export default Marking
