import { ComponentType } from "react"
import { useAssets } from "./providers/assets"
import { PatternsKind, ColorPlaceholder } from "../types/design"
import { useEditor } from "./providers/editor"
import PatternSelector from "./PatternSelector"
import ColorPalette from "./ColorPalette"
import ColorPlaceholders from "./ColorPlaceholders"
import { ControlBlock } from "./style/editorControl"
import { Category, ColorPlaceholderSelectorKind, SubCategory } from "../types/editor"

const PatternAndColor: ComponentType<Props> = ({ title, titleNumber, kind, category, subCategory, colorPlaceholderSelectorKind, colorPaletteTags }) => {
    const { assets } = useAssets()
    const { editor } = useEditor()
    const patternAssetId = editor.design!.patternAssetIds[kind]
    const patternAsset = assets.getPatternAsset(kind, patternAssetId)!
    const patternColorPlaceholders = assets.getColorPlaceholders(kind, patternAsset.id)
    const patternColorSelected = editor.selectedColorPlaceholder == null ? false : patternColorPlaceholders.includes(editor.selectedColorPlaceholder)

    const handleSelectPattern = (assetId: string) => {
        editor.changePattern(kind, assetId)
    }
    const isSelectPattern = (assetId: string) => {
        return assetId === editor.design!.getPatternAssetId(kind)
    }

    const colorPaletteTag = ():(string|undefined) => {
        if (colorPaletteTags == null) {
            return undefined
        }
        const tag = colorPaletteTags[editor.selectedColorPlaceholder!]
        return tag
    }

    return (
        <ControlBlock
            title={title}
            titleNumber={titleNumber}
            disabled={editor.isSubcategoryDisabled(subCategory)}
            selected={editor.selectedSubCategories[category] === subCategory}
        >
            <div className="seletNameColor">
                <div className="nameBox">{patternAsset.label}</div>
                <ColorPlaceholders colorPlaceholders={editor.getColorPlaceholders(colorPlaceholderSelectorKind)} enableColorPlaceholders={assets.getColorPlaceholders(kind, patternAsset.id)} />
            </div>

            <ColorPalette
                visible={editor.isColorPaletteVisible(colorPlaceholderSelectorKind)}
                enable={patternColorSelected}
                colorPlaceholder={editor.selectedColorPlaceholder!}
                tag={colorPaletteTag()}
            />
            <PatternSelector kind={kind} onSelect={handleSelectPattern} isSelect={isSelectPattern} />
        </ControlBlock>
    )
}

export default PatternAndColor

interface Props {
    title: string
    titleNumber: string
    kind: PatternsKind
    category: Category
    subCategory: SubCategory
    colorPlaceholderSelectorKind: ColorPlaceholderSelectorKind
    colorPaletteTags?: {[key in ColorPlaceholder]?: string|undefined}
}
