import tinycolor from "tinycolor2";
import { RGBColor } from "../../types/asset";
import { Layer } from "./Layer";
import { Border, Rect, TextLayout } from "./types";

class TextLayer implements Layer {
    constructor(
        readonly width: number,
        readonly height: number,
        readonly text: string,
        readonly rect: Rect,
        readonly layout: TextLayout,
        readonly color: RGBColor,
        readonly fontSize: number,
        readonly fontFamily: string,
        readonly borders: Border[],
        readonly debug: boolean,
    ) { }

    async draw(dst: HTMLCanvasElement): Promise<void> {
        const ctx = dst.getContext('2d')!
        if (this.debug) {
            this.drawDebugBG(ctx, 'rgba(0,0,255,0.2)')
        }
        ctx.save()
        const { x, y } = this.rect.lt

        ctx.font = `${this.calcFontSize(ctx)}px ${this.fontFamily}`
        ctx.fillStyle = `${tinycolor(this.color).toRgbString()}`
        ctx.translate(x, y)
        if (this.layout.rotate != null) {
            ctx.translate(this.rect.w / 2, this.rect.h / 2)
            ctx.rotate(this.layout.rotate)
            ctx.translate(-this.rect.w / 2, -this.rect.h / 2)
        }
        if (this.debug) {
            this.drawDebugBG2(ctx, 'rgba(255,0,0,0.2)')
        }

        const strokeBorder = (text: string, x: number, y: number, border: Border, i: number) => {
            try {
                ctx.save()
                ctx.strokeStyle = `${tinycolor(border.color).toRgbString()}`
                ctx.lineWidth = border.borderWidth * (i + 1)
                ctx.strokeText(text, x, y)
            } finally {
                ctx.restore()
            }
        }

        const drawText: drawText = (text, x, y) => {
            ctx.save()

            ctx.lineJoin = 'bevel'
            ctx.miterLimit = 10.0
            for (let i = 0; i < this.borders.length; i++) {
                const border = this.borders[i]
                strokeBorder(text, x, y, border, i)
            }
            ctx.fillText(text, x, y)
            ctx.restore()
        }

        this.translateV(ctx)
        switch (this.layout.hAlign) {
            case 'spacing':
                if (this.layout.archR != null) {
                    this.drawArchSpacingText(ctx, drawText, this.layout.archR)
                } else {
                    this.drawSpacingText(ctx, drawText)
                }
                break
            case 'center':
                this.drawCenterAlignText(ctx, drawText)
                break
            case 'left':
                this.drawLeftAlignText(ctx, drawText)
                break
            case 'right':
                this.drawRightAlignText(ctx, drawText)
                break
        }
        ctx.restore()
    }

    load(): Promise<void>[] {
        return []
    }

    private translateV = (ctx: CanvasRenderingContext2D) => {
        ctx.textBaseline = this.layout.baseline == null ? 'middle' : this.layout.baseline
        switch (this.layout.vAlign) {
            case 'center':
                ctx.translate(0, this.rect.h / 2)
                break
            case 'top':
                break
            case 'bottom':
                ctx.translate(0, this.rect.h)
                break
        }
    }

    private drawSpacingText(ctx: CanvasRenderingContext2D, drawText: drawText) {
        if (this.text.length < 2) {
            return this.drawCenterAlignText(ctx, drawText)
        }
        const sp = this.getSpacing(ctx)
        ctx.translate(sp, 0)
        for (let i = 0; i < this.text.length; i++) {
            // ctx.fillText(`${this.text[i]}`, 0, 0)
            drawText(`${this.text[i]}`, 0, 0)
            ctx.translate(sp + this.measureTextWidth(ctx, this.text[i]), 0)
        }
    }

    private drawArchSpacingText(ctx: CanvasRenderingContext2D, drawText: drawText, archR: number) {
        if (this.debug) {
            ctx.save()
            ctx.lineWidth = this.rect.h
            ctx.strokeStyle = 'rgba(0,255,0,0.2)'
            ctx.beginPath()
            // ctx.arc(x, y, archR, -lToRg(-this.rect.w), lToRg(this.rect.w), false)
            ctx.arc(this.rect.w / 2, archR, archR, 0, 2 * Math.PI, false)
            ctx.stroke()
            ctx.restore()
        }

        if (this.text.length < 2) {
            return this.drawCenterAlignText(ctx, drawText)
        }
        const lToRg = (l: number): number => {
            const rg = l / archR
            console.log({'PI': Math.PI, l, archR, rg})
            return rg
        }
        const iniRG = lToRg(-this.rect.w)
        const sp = this.getSpacing(ctx)

        ctx.translate(this.rect.w / 2, archR)
        ctx.rotate(-lToRg(this.rect.w / 2))
        console.log({iniRG})

        ctx.rotate(lToRg(sp))

        for (let i = 0; i < this.text.length; i++) {
            drawText(`${this.text[i]}`, 0, -archR)
            // ctx.translate(sp + this.measureTextWidth(ctx, this.text[i]), 0)
            const rg = lToRg(sp + this.measureTextWidth(ctx, this.text[i]))
            console.log({rg})
            ctx.rotate(rg)
        }


    }

    private drawLeftAlignText(ctx: CanvasRenderingContext2D, drawText: drawText) {
        const orig = ctx.textAlign
        ctx.textAlign = 'left'
        // ctx.fillText(this.text, 0, 0)
        drawText(this.text, 0, 0)
        ctx.textAlign = orig
    }

    private drawRightAlignText(ctx: CanvasRenderingContext2D, drawText: drawText) {
        ctx.translate(this.rect.w - this.measureTextWidth(ctx, this.text), 0)
        const orig = ctx.textAlign
        ctx.textAlign = 'left'
        // ctx.fillText(this.text, 0, 0)
        drawText(this.text, 0, 0)
        ctx.textAlign = orig
    }

    private drawCenterAlignText(ctx: CanvasRenderingContext2D, drawText: drawText) {
        const textw = this.measureTextWidth(ctx, this.text)
        ctx.translate(this.rect.w / 2 - textw / 2, 0)
        const orig = ctx.textAlign
        ctx.textAlign = 'left'
        // ctx.fillText(this.text, 0, 0)
        drawText(this.text, 0, 0)
        ctx.textAlign = orig
    }

    private getSpacing(ctx: CanvasRenderingContext2D): number {
        if (this.text.length <= 1) {
            return 0
        }
        const textWidth = this.measureTextWidth(ctx, this.text)
        return (this.rect.w - textWidth) / (this.text.length - 1 + 2)
    }

    private measureTextWidth(ctx: CanvasRenderingContext2D, text: string): number {
        const mtx = ctx.measureText(text)
        return mtx.width
    }

    private drawDebugBG(ctx: CanvasRenderingContext2D, fillStyle: string) {
        ctx.save()
        ctx.fillStyle = fillStyle
        ctx.fillRect(this.rect.x, this.rect.y, this.rect.w, this.rect.h)
        ctx.restore()
    }

    private drawDebugBG2(ctx: CanvasRenderingContext2D, fillStyle: string) {
        ctx.save()
        ctx.fillStyle = fillStyle
        ctx.fillRect(0, 0, this.rect.w, this.rect.h)
        ctx.restore()
    }

    private calcFontSize(ctx: CanvasRenderingContext2D) {
        let fontSize = this.fontSize
        while (fontSize > 0) {
            ctx.save()
            ctx.font = `${fontSize}px ${this.fontFamily}`
            const w = this.measureTextWidth(ctx, this.text)
            if (w <= this.rect.w) {
                break
            }
            ctx.restore()
            fontSize--
        }
        if (fontSize > 0) {
            return fontSize
        }
        return this.fontSize
    }
}

export default TextLayer

type drawText = (text: string, x: number, y: number) => any
