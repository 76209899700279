import { Assets, AssetsServer, ColorAsset, Font, NestedPatterns } from "../types/asset"
import { ColorPlaceholder, NestedPatternPlacement, PatternsAssetKind } from "../types/design"

const tinycolor = require('tinycolor2')

const toColorPlaceholder = (v: string): ColorPlaceholder => {
    return v as ColorPlaceholder
}

const toNestedPatterns = (v: { placement: string, patternsKind: string, colorPlaceholders: string[] }): NestedPatterns => {
    const { placement, colorPlaceholders, patternsKind: patternKind } = v
    return {
        placement: placement as NestedPatternPlacement,
        colorPlaceholders: colorPlaceholders as ColorPlaceholder[],
        patternsKind: patternKind as PatternsAssetKind,
    }
}

const nestPatternThum00 = require('./images/thum_gara00.png')
const nestPatternThum01 = require('./images/thum_gara01.png')
const nestPatternThum02 = require('./images/thum_gara02.png')
const nestPatternThum03 = require('./images/thum_gara03.png')
const nestPatternThum04 = require('./images/thum_gara04.png')
const nestPatternThum05 = require('./images/thum_gara05.png')
const nestPatternThum06 = require('./images/thum_gara06.png')
const nestPatternThum07 = require('./images/thum_gara07.png')

const samplePatternBase = require('./images/shirtNest/base.png')
const nestPattern1 = require('./images/shirtNest/gara01.png')
const nestPattern2 = require('./images/shirtNest/gara02.png')
const nestPattern3 = require('./images/shirtNest/gara03.png')
const nestPattern4 = require('./images/shirtNest/gara04.png')
const nestPattern5 = require('./images/shirtNest/gara05.png')
const nestPattern6 = require('./images/shirtNest/gara06.png')
const nestPattern7 = require('./images/shirtNest/gara07.png')
const shirtsDesignThumNew01 = require('./images/shirtsDesignThumNew01.png')
const shirtsDesignThumNew02 = require('./images/shirtsDesignThumNew02.png')
const shirtsDesignThumNew03 = require('./images/shirtsDesignThumNew03.png')
const shirtsDesignThumNew04 = require('./images/shirtsDesignThumNew04.png')
const shirtsDesignThumNew05 = require('./images/shirtsDesignThumNew05.png')

const ref_man01_front = require('./images/ref/front_ref.png')
const ref_man01_back = require('./images/ref/back_ref.png')
const ref_man02_front = require('./images/ref/kodomo_front_ref.png')
const ref_man02_back = require('./images/ref/kodomo_back_ref.png')

const shadow_man01_front = require('./images/shadow/front_shadow.png')
const shadow_man01_back = require('./images/shadow/back_shadow.png')
const shadow_man02_front = require('./images/shadow/kodomo_front_shadow.png')
const shadow_man02_back = require('./images/shadow/kodomo_back_shadow.png')

const ref_sideL = require('./images/ref/left_ref.png')
const ref_sideR = require('./images/ref/right_ref.png')

const shadow_sideL = require('./images/shadow/left_shadow.png')
const shadow_sideR = require('./images/shadow/right_shadow.png')

const blankImg = require('./images/blankImg.png')

const front_base = require('./images/shirts/front_base.png')
const back_base = require('./images/shirts/back_base.png')
const left_base = require('./images/shirts/left_base.png')
const right_base = require('./images/shirts/right_base.png')

const front_btn = require('./images/shirts/front_btnbase.png')
const left_btn = require('./images/shirts/left_btnbase.png')
const right_btn = require('./images/shirts/right_btnbase.png')

const kaigo_front_design01 = require('./images/shirts/front_design01.png')
const kaigo_front_design02 = require('./images/shirts/front_design02.png')
const kaigo_front_design03 = require('./images/shirts/front_design03.png')
const kaigo_front_design04 = require('./images/shirts/front_design04.png')
const kaigo_front_design05 = require('./images/shirts/front_design05.png')

const kaigo_back_design01 = require('./images/shirts/back_design01.png')
const kaigo_back_design02 = require('./images/shirts/back_design02.png')
const kaigo_back_design03 = require('./images/shirts/back_design03.png')
const kaigo_back_design04 = require('./images/shirts/back_design04.png')
const kaigo_back_design05 = require('./images/shirts/back_design05.png')

const kaigo_left_design01 = require('./images/shirts/left_design01.png')
const kaigo_left_design02 = require('./images/shirts/left_design02.png')
const kaigo_left_design03 = require('./images/shirts/left_design03.png')
const kaigo_left_design04 = require('./images/shirts/left_design04.png')
const kaigo_left_design05 = require('./images/shirts/left_design05.png')

const kaigo_right_design01 = require('./images/shirts/right_design01.png')
const kaigo_right_design02 = require('./images/shirts/right_design02.png')
const kaigo_right_design03 = require('./images/shirts/right_design03.png')
const kaigo_right_design04 = require('./images/shirts/right_design04.png')
const kaigo_right_design05 = require('./images/shirts/right_design05.png')

export class BundleAssetsServer implements AssetsServer {
    async getAssets(): Promise<Assets> {
        return new Assets({
            manImage: assets.manImage,
            refImage: assets.refImage,
            shadowImage: assets.shadowImage,
            innerShirtImage: assets.innerShirtImage,
            shirtLogoImages: {
                man01: {
                    centerBlack: blankImg,
                    centerWhite: blankImg,
                    leftBlack: blankImg,
                    leftWhite: blankImg,
                    rightBlack: blankImg,
                    rightWhite: blankImg,
                },
                man02: {
                    centerBlack: blankImg,
                    centerWhite: blankImg,
                    leftBlack: blankImg,
                    leftWhite: blankImg,
                    rightBlack: blankImg,
                    rightWhite: blankImg,
                },
            },
            pantsLogoImages: {
                man01: {
                    leftFrontBlack: blankImg,
                    leftFrontWhite: blankImg,
                    leftBackBlack: blankImg,
                    leftBackWhite: blankImg,
                    rightFrontBlack: blankImg,
                    rightFrontWhite: blankImg,
                    rightBackBlack: blankImg,
                    rightBackWhite: blankImg,
                },
                man02: {
                    leftFrontBlack: blankImg,
                    leftFrontWhite: blankImg,
                    leftBackBlack: blankImg,
                    leftBackWhite: blankImg,
                    rightFrontBlack: blankImg,
                    rightFrontWhite: blankImg,
                    rightBackBlack: blankImg,
                    rightBackWhite: blankImg,
                },
            },
            patternsAssets: {
                shirt: assets.shirts,
                shirtNest: assets.shirtNest,
                eri: assets.eri,
                pants: assets.pants,
                socks: assets.socks,
            },
            colors: assets.colors.map<ColorAsset>(v => {
                const tc = tinycolor(v.value)
                return {
                    id: v.id,
                    label: v.label,
                    rgb: tc.toRgb(),
                    tags: v.tags,
                }
            }),
            fonts: assets.fonts.map(v => new Font(v)),
        })
    }
}

const assets = {
    "version": "v1",
    "manImage": {
        "front": {
            "man01": blankImg,
            "man02": blankImg,
        },
        "back": {
            "man01": blankImg,
            "man02": blankImg,
        },
        "hira": {},
    },
    "refImage": {
        "front": {
            "man01": ref_man01_front,
            "man02": ref_man02_front,
        },
        "back": {
            "man01": ref_man01_back,
            "man02": ref_man02_back,
        },
        "hira": {
            "man01": ref_sideL,
            "man02": ref_sideR,
        },
    },
    "shadowImage": {
        "front": {
            "man01": shadow_man01_front,
            "man02": shadow_man02_front,
        },
        "back": {
            "man01": shadow_man01_back,
            "man02": shadow_man02_back,
        },
        "hira": {
            "man01": shadow_sideL,
            "man02": shadow_sideR,
        },
    },
    "innerShirtImage": {
        "front": {
            "man01": blankImg,
            "man02": blankImg,
        },
        "back": {
            "man01": blankImg,
            "man02": blankImg,
        },
    },
    "shirts": [

        {
            "id": "shirt0001",
            "label": "シャツデザインA",
            "thumb": shirtsDesignThumNew01,
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("shirtMain"),
                    "image": {
                        "front": {
                            "man01": front_base,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": back_base,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_base,
                            "man02": right_base,
                        }
                    }
                },
                {
                    // "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "nestedPatterns": toNestedPatterns({
                        "patternsKind": 'shirtNest',
                        "placement": "shirtNest1",
                        "colorPlaceholders": ["shirtNest1Main", "shirtNest1Sub1"],
                    }),
                    "image": {
                        "front": {
                            "man01": kaigo_front_design01,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": kaigo_back_design01,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": kaigo_left_design01,
                            "man02": kaigo_right_design01,
                        }
                    }
                },
                {
                    "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "image": {
                        "front": {
                            "man01": front_btn,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_btn,
                            "man02": right_btn,
                        }
                    }
                },
            ]
        },
        {
            "id": "shirt0003",
            "label": "シャツデザインB",
            "thumb": shirtsDesignThumNew02,
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("shirtMain"),
                    "image": {
                        "front": {
                            "man01": front_base,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": back_base,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_base,
                            "man02": right_base,
                        }
                    }
                },
                {
                    // "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "nestedPatterns": toNestedPatterns({
                        "patternsKind": 'shirtNest',
                        "placement": "shirtNest1",
                        "colorPlaceholders": ["shirtNest1Main", "shirtNest1Sub1"],
                    }),
                    "image": {
                        "front": {
                            "man01": kaigo_front_design02,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": kaigo_back_design02,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": kaigo_left_design02,
                            "man02": kaigo_right_design02,
                        }
                    }
                },
                {
                    "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "image": {
                        "front": {
                            "man01": front_btn,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_btn,
                            "man02": right_btn,
                        }
                    }
                },

            ]
        },
        {
            "id": "shirt0004",
            "label": "シャツデザインC",
            "thumb": shirtsDesignThumNew03,
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("shirtMain"),
                    "image": {
                        "front": {
                            "man01": front_base,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": back_base,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_base,
                            "man02": right_base,
                        }
                    }
                },
                {
                    // "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "nestedPatterns": toNestedPatterns({
                        "patternsKind": 'shirtNest',
                        "placement": "shirtNest1",
                        "colorPlaceholders": ["shirtNest1Main", "shirtNest1Sub1"],
                    }),
                    "image": {
                        "front": {
                            "man01": kaigo_front_design03,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": kaigo_back_design03,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": kaigo_left_design03,
                            "man02": kaigo_right_design03,
                        }
                    }
                },
                {
                    "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "image": {
                        "front": {
                            "man01": front_btn,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_btn,
                            "man02": right_btn,
                        }
                    }
                },
            ]
        },
        {
            "id": "shirt0005",
            "label": "シャツデザインD",
            "thumb": shirtsDesignThumNew04,
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("shirtMain"),
                    "image": {
                        "front": {
                            "man01": front_base,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": back_base,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_base,
                            "man02": right_base,
                        }
                    }
                },
                {
                    // "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "nestedPatterns": toNestedPatterns({
                        "patternsKind": 'shirtNest',
                        "placement": "shirtNest1",
                        "colorPlaceholders": ["shirtNest1Main", "shirtNest1Sub1"],
                    }),
                    "image": {
                        "front": {
                            "man01": kaigo_front_design04,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": kaigo_back_design04,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": kaigo_left_design04,
                            "man02": kaigo_right_design04,
                        }
                    }
                },
                {
                    "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "image": {
                        "front": {
                            "man01": front_btn,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_btn,
                            "man02": right_btn,
                        }
                    }
                },
            ]
        },
        {
            "id": "shirt0011",
            "label": "シャツデザインE",
            "thumb": shirtsDesignThumNew05,
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("shirtMain"),
                    "image": {
                        "front": {
                            "man01": front_base,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": back_base,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_base,
                            "man02": right_base,
                        }
                    }
                },
                {
                    // "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "nestedPatterns": toNestedPatterns({
                        "patternsKind": 'shirtNest',
                        "placement": "shirtNest1",
                        "colorPlaceholders": ["shirtNest1Main", "shirtNest1Sub1"],
                    }),
                    "image": {
                        "front": {
                            "man01": kaigo_front_design05,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": kaigo_back_design05,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": kaigo_left_design05,
                            "man02": kaigo_right_design05,
                        }
                    }
                },
                {
                    "colorPlaceholder": toColorPlaceholder("shirtSub1"),
                    "image": {
                        "front": {
                            "man01": front_btn,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": left_btn,
                            "man02": right_btn,
                        }
                    }
                },
            ]
        }
    ],
    "shirtNest": [
        {
            "id": "shirtNest0000",
            "label": "ベタ塗り",
            "thumb": nestPatternThum00,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
            ],
        },
        {
            "id": "shirtNest0001",
            "label": "柄1",
            "thumb": nestPatternThum01,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern1,
                            "man02": nestPattern1,
                        },
                        "back": {
                            "man01": nestPattern1,
                            "man02": nestPattern1,
                        },
                        "hira": {
                            "man01": nestPattern1,
                            "man02": nestPattern1,
                        },
                    },
                }
            ],
        },
        {
            "id": "shirtNest0002",
            "label": "柄2",
            "thumb": nestPatternThum02,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern2,
                            "man02": nestPattern2,
                        },
                        "back": {
                            "man01": nestPattern2,
                            "man02": nestPattern2,
                        },
                        "hira": {
                            "man01": nestPattern2,
                            "man02": nestPattern2,
                        },
                    },
                }
            ],
        },
        {
            "id": "shirtNest0003",
            "label": "柄3",
            "thumb": nestPatternThum03,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern3,
                            "man02": nestPattern3,
                        },
                        "back": {
                            "man01": nestPattern3,
                            "man02": nestPattern3,
                        },
                        "hira": {
                            "man01": nestPattern3,
                            "man02": nestPattern3,
                        },
                    },
                }
            ],
        },
        {
            "id": "shirtNest0004",
            "label": "柄4",
            "thumb": nestPatternThum04,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern4,
                            "man02": nestPattern4,
                        },
                        "back": {
                            "man01": nestPattern4,
                            "man02": nestPattern4,
                        },
                        "hira": {
                            "man01": nestPattern4,
                            "man02": nestPattern4,
                        },
                    },
                }
            ],
        },
        {
            "id": "shirtNest0005",
            "label": "柄5",
            "thumb": nestPatternThum05,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern5,
                            "man02": nestPattern5,
                        },
                        "back": {
                            "man01": nestPattern5,
                            "man02": nestPattern5,
                        },
                        "hira": {
                            "man01": nestPattern5,
                            "man02": nestPattern5,
                        },
                    },
                }
            ],
        },
        {
            "id": "shirtNest0006",
            "label": "柄6",
            "thumb": nestPatternThum06,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern6,
                            "man02": nestPattern6,
                        },
                        "back": {
                            "man01": nestPattern6,
                            "man02": nestPattern6,
                        },
                        "hira": {
                            "man01": nestPattern6,
                            "man02": nestPattern6,
                        },
                    },
                }
            ],
        },
        {
            "id": "shirtNest0007",
            "label": "柄7",
            "thumb": nestPatternThum07,
            "patterns": [
                {
                    "image": {
                        "front": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "back": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                        "hira": {
                            "man01": samplePatternBase,
                            "man02": samplePatternBase,
                        },
                    },
                },
                {
                    "image": {
                        "front": {
                            "man01": nestPattern7,
                            "man02": nestPattern7,
                        },
                        "back": {
                            "man01": nestPattern7,
                            "man02": nestPattern7,
                        },
                        "hira": {
                            "man01": nestPattern7,
                            "man02": nestPattern7,
                        },
                    },
                }
            ],
        },
    ],
    "eri": [
        {
            "id": "eri0001",
            "label": "丸首",
            "thumb": blankImg,
            "maskImage": {
                "front": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
                "back": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
                "hira": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
            },
            "refImage": {
                "front": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
                "back": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
                "hira": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
            },
            "shadowImage": {
                "front": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
                "back": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
                "hira": {
                    "man01": blankImg,
                    "man02": blankImg,
                },
            },
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("eriMain"),
                    "image": {
                        "front": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                    },
                }
            ],
        },
    ],
    "pants": [
        {
            "id": "pants0001",
            "label": "フランス代表風",
            "thumb": blankImg,
            "patterns": [{
                "colorPlaceholder": toColorPlaceholder("pantsMain"),
                "image": {
                    "front": {
                        "man01": blankImg,
                        "man02": blankImg,
                    },
                    "back": {
                        "man01": blankImg,
                        "man02": blankImg,
                    },
                    "hira": {
                        "man01": blankImg,
                        "man02": blankImg,
                    }
                }
            },
            ]
        },
    ],
    innerShirts: [
        {
            "id": "innerShirt_none",
            "label": "インナーなし",
            "thumb": blankImg,
            "patterns": [],
            "none": true,
        },
    ],
    socks: [
        {
            "id": "socks0001",
            "label": "ソックスA",
            "thumb": blankImg,
            "patterns": [
                {
                    "colorPlaceholder": toColorPlaceholder("socksMain"),
                    "image": {
                        "front": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "back": {
                            "man01": blankImg,
                            "man02": blankImg,
                        },
                        "hira": {},
                    },
                },
            ],
        },


    ],
    "colors": [
        {
            "id": "color0001",
            "label": "ホワイト",
            "value": "#ffffff",
            "tags": ["button"],
        },
        {
            "id": "color0002",
            "label": "ブラック",
            "value": "#2a2a2a",
            "tags": ["button"],
        },
        {
            "id": "color0003",
            "label": "グレー",
            "value": "#898989",
            "tags": [],
        },
        {
            "id": "color0004",
            "label": "ブラウン",
            "value": "#804c2d",
            "tags": [],
        },
        {
            "id": "color0005",
            "label": "ライトイエロー",
            "value": "#fff57f",
            "tags": [],
        },
        {
            "id": "color0006",
            "label": "イエロー",
            "value": "#fff100",
            "tags": [],

        },
        {
            "id": "color0007",
            "label": "ライトオレンジ",
            "value": "#f39a63",
            "tags": [],

        },
        {
            "id": "color0008",
            "label": "オレンジ",
            "value": "#ed6d00",
            "tags": [],

        },
        {
            "id": "color0009",
            "label": "ライトピンク",
            "value": "#f4b4d0",
            "tags": [],

        },
        {
            "id": "color0010",
            "label": "ピンク",
            "value": "#e95098",
            "tags": [],

        },
        {
            "id": "color0011",
            "label": "レッド",
            "value": "#e60012",
            "tags": [],

        },
        {
            "id": "color0012",
            "label": "エンジ",
            "value": "#94252a",
            "tags": [],

        },
        {
            "id": "color0013",
            "label": "サックス",
            "value": "#52c3f1",
            "tags": [],

        },
        {
            "id": "color0014",
            "label": "ブルー",
            "value": "#005bac",
            "tags": [],

        },
        {
            "id": "color0015",
            "label": "ダークブルー",
            "value": "#1f2a66",
            "tags": [],

        },
        {
            "id": "color0016",
            "label": "パープル",
            "value": "#915da3",
            "tags": [],
        },
        {
            "id": "color0017",
            "label": "ライムグリーン",
            "value": "#dae124",
            "tags": [],
        },
        {
            "id": "color0018",
            "label": "ライトグリーン",
            "value": "#71af43",
            "tags": [],

        },
        {
            "id": "color0019",
            "label": "グリーン",
            "value": "#007440",
            "tags": [],

        },
        {
            "id": "color0020",
            "label": "ダークグリーン",
            "value": "#064738",
            "tags": [],
        },
        {
            "id": "color0021",
            "label": "ネイビー",
            "value": "#233B6C",
            "tags": ["button"],
        },
    ],
    fonts: [
        {
            "id": "originalNumber01",
            "family": "Type01 バイエルン_A",
            "url": require('./fonts/No1-Regular.ttf'),
            "style": "normal",
            "weight": "400",
            "tags": ["number"],
            "sample": {
                "default": `xABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`,
                "number": "0123456789",
                "jp": "ちー\nむす",
            },
            "sizeBias": 1.0,
        }
    ],
    "url": "http://localhost:3001/assets"
}
