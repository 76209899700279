import { ComponentType } from "react"
import tinycolor from "tinycolor2"
import { useAssets } from "./providers/assets"
import { ColorAsset, RGBColor } from "../types/asset"
import { ColorPlaceholder } from "../types/design"
import { useEditor } from "./providers/editor"
import { colorPlaceholderLabels } from "./strings"

const ColorPlaceholders: ComponentType<Props> = ({ colorPlaceholders, enableColorPlaceholders }) => {
    const { assets } = useAssets()
    const { editor } = useEditor()
    const colors = colorPlaceholders.map<{ pl: ColorPlaceholder, color: ColorAsset }>(pl => {
        const colorId = editor.design!.colors[pl]
        const color = assets.getColor(colorId)!
        return {
            pl,
            color,
        }
    })
    const handleClickEdit = (colorPlaceholder: ColorPlaceholder) => () => {
        editor.selectColorPlaceholder(colorPlaceholder)
    }
    const itemClassName = (item: typeof colors[number]) => {
        const a = ['colorBtn']
        if (item.pl === editor.selectedColorPlaceholder) {
            a.push('select')
        }
        return a.join(' ')
    }
    const itemOuterClassName = (item: typeof colors[number]) => {
        const a = []
        if (!enableColorPlaceholders.includes(item.pl)) {
            a.push('disable')
        }
        return a.join(' ')
    }
    return (
        <>
            <div className="colorSelector">
                {colors.map(v => (
                    <div
                        key={`${v.pl}`}
                        className={itemOuterClassName(v)}
                    >
                        <div
                            className={itemClassName(v)}
                            style={{
                                backgroundColor: getRGBColorString(v.color.rgb),
                            }}
                            title={`${colorPlaceholderLabels[v.pl]}: ${v.color.label}`}
                            onClick={handleClickEdit(v.pl)}
                        ></div>
                        <div className="colorName" onClick={handleClickEdit(v.pl)}>{colorPlaceholderLabels[v.pl]}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ColorPlaceholders

interface Props {
    colorPlaceholders: ColorPlaceholder[]
    enableColorPlaceholders: ColorPlaceholder[]
}

const getRGBColorString = (rgb: RGBColor): string => {
    return tinycolor({ r: rgb.r, g: rgb.g, b: rgb.b }).toRgbString()
}
