import { Layer, NullLayer } from "./Layer"

// TODO:
type GlobalCompositeOperation = "color" | "color-burn" | "color-dodge" | "copy" | "darken" | "destination-atop" | "destination-in" | "destination-out" | "destination-over" | "difference" | "exclusion" | "hard-light" | "hue" | "lighten" | "lighter" | "luminosity" | "multiply" | "overlay" | "saturation" | "screen" | "soft-light" | "source-atop" | "source-in" | "source-out" | "source-over" | "xor";

class FBlendLayer implements Layer {
    readonly width: number
    readonly height: number
    private layer1: Layer
    private layer2: Layer
    private f: GlobalCompositeOperation

    constructor(width: number, height: number, layer1: Layer, layer2: Layer, f: GlobalCompositeOperation) {
        this.width = width
        this.height = height
        this.layer1 = layer1
        this.layer2 = layer2
        this.f = f
    }

    async draw(dst: HTMLCanvasElement) {
        await this.drawLayer(dst, this.layer1, 'source-over')
        await this.drawLayer(dst, this.layer2, this.f)
    }

    load(): Promise<void>[] {
        return [
            ...this.layer1.load(),
            ...this.layer2.load(),
        ]
    }

    private async drawLayer(dst: HTMLCanvasElement, layer: Layer, f: GlobalCompositeOperation) {
        const tmp = document.createElement('canvas')
        tmp.width = layer.width
        tmp.height = layer.height
        const dstCtx = dst.getContext('2d')!
        dstCtx.save()
        await layer.draw(tmp)
        const orig = dstCtx.globalCompositeOperation
        dstCtx.globalCompositeOperation = f
        dstCtx.drawImage(tmp, 0, 0)
        dstCtx.globalCompositeOperation = orig
        dstCtx.restore()
    }
}

export default FBlendLayer