import { ComponentType } from "react";
import { useEditor } from "./providers/editor";
import { Category } from "../types/editor";

const Navi: ComponentType<Props> = () => {
    const { editor } = useEditor()
    const handleClickItem = (item: Category) => () => {
        editor.changeCategory(item)
    }
    const props = (categClassName:string, categ:Category) => {
        const a = [categClassName]

        if (categ === editor.category) {
            a.push('select')
        }
        const className = a.join(' ')
        return {
            className,
            onClick: handleClickItem(categ)
        }
    }
    return (
        <section className="simulationNavi">
            <nav className="navi">
                <ul>
                    <li {...props('shirts', 'shirt')} title="シャツ">
                        <svg version="1.1" id="&#x30EC;&#x30A4;&#x30E4;&#x30FC;_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 100 100">
                            <g>
                                <path d="M8.475,26.331v20.302h13.498c2.307-11.096-1.317-20.938-2.792-24.286L8.475,26.331z"/>
                                <path d="M77.841,21.238l-14.134-5.26C61.943,21.842,56.475,26.118,50,26.118c-6.476,0-11.943-4.276-13.706-10.139l-14.134,5.26c1.78,4.035,5.767,15.13,2.548,27.692v35.092h50.584V48.929
                C72.074,36.368,76.06,25.273,77.841,21.238z"/>
                                <path d="M80.82,22.347c-1.475,3.347-5.099,13.19-2.792,24.285h13.497V26.331L80.82,22.347z"/>
                            </g>
                        </svg>
                    </li>
                </ul>
            </nav>
        </section>
    )
}

export default Navi

interface Props { }
