import { Layer } from "./Layer";

class ClearLayer implements Layer {
    readonly width: number
    readonly height: number
    srcLayer: Layer

    constructor(srcLayer: Layer) {
        this.width = srcLayer.width
        this.height = srcLayer.height
        this.srcLayer = srcLayer
    }

    async draw(dst: HTMLCanvasElement) {
        const ctx = dst.getContext('2d')!
        ctx.save()
        ctx.clearRect(0, 0, this.width, this.height)
        await this.srcLayer.draw(dst)
        ctx.restore()
    }

    load(): Promise<void>[] {
        return [...this.srcLayer.load()]
    }
}

export default ClearLayer
