import { RGBColor } from "../../types/asset";
import { Layer } from "./Layer";

class TintLayer implements Layer {
    private srcLayer: Layer
    readonly width: number
    readonly height: number
    readonly color: RGBColor

    constructor(srcLayer: Layer, color: RGBColor) {
        this.srcLayer = srcLayer
        this.width = srcLayer.width
        this.height = srcLayer.height
        this.color = color
    }

    async draw(dst: HTMLCanvasElement): Promise<void> {
        const tmp: HTMLCanvasElement = document.createElement('canvas')
        tmp.width = this.width
        tmp.height = this.height
        await this.srcLayer.draw(tmp)
        const dstCtx = dst.getContext('2d')!
        dstCtx.save()
        const tmpCtx = tmp.getContext('2d')!
        tmpCtx.save()
        const imageData = tmpCtx.getImageData(0, 0, this.width, this.height)
        const data = imageData.data
        for (var j = 0; j < data.length; j += 4) {
            data[j] = this.color.r
            data[j + 1] = this.color.g
            data[j + 2] = this.color.b
        }
        tmpCtx.putImageData(imageData, 0, 0)
        dstCtx.drawImage(tmp, 0, 0)
        tmpCtx.restore()
        dstCtx.restore()
    }

    load(): Promise<void>[] {
        return [...this.srcLayer.load()]
    }
}

export default TintLayer