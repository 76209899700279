export const shirtSizes = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'] as const

export type ShirtSize = typeof shirtSizes[number]

export type Order = {
    id?: string
    dairitenName: string
    dairitenTantouName: string
    dairitenZipcode: string
    dairitenAddress: string
    dairitenTel: string
    dairitenEmail: string
    destName: string
    destZipcode: string
    destAddress: string
    destTel: string
    sizeNums: {
        [key in ShirtSize]: number
    }
    remark: string
    snapshotFrontUrl: string
    snapshotBackUrl: string
    ankenName: string

    shirtName: string
    baseColorRgb: string
    baseColorName: string
    buttonColorRgb: string
    buttonColorName: string
    garaName: string
    garaMainColorRgb: string
    garaMainColorName: string
    garaSubColorRgb: string
    garaSubColorName: string
}
