import { Layer, NullLayer } from "./Layer"

class BlendLayer implements Layer {
    readonly width: number
    readonly height: number
    private layers: Layer[]

    constructor(width: number, height: number, layers: Layer[]) {
        this.width = width
        this.height = height
        this.layers = layers
    }

    async draw(dst: HTMLCanvasElement) {
        for (let i = 0; i < this.layers.length; i++) {
            if (this.layers[i] === NullLayer) {
                continue
            }
            await this.drawLayer(dst, this.layers[i])
        }
    }

    load(): Promise<void>[] {
        return this.layers.reduce<Promise<void>[]>((p, c) => ([...p, ...c.load()]), [])
    }

    private async drawLayer(dst: HTMLCanvasElement, layer: Layer) {
        const tmp = document.createElement('canvas')
        tmp.width = layer.width
        tmp.height = layer.height
        const dstCtx = dst.getContext('2d')!
        dstCtx.save()
        await layer.draw(tmp)
        const orig = dstCtx.globalCompositeOperation
        // const ope = 'lighter'
        // const ope = ''
        // dstCtx.globalCompositeOperation = "copy"
        dstCtx.drawImage(tmp, 0, 0)
        dstCtx.globalCompositeOperation = orig
        dstCtx.restore()
    }
}

export default BlendLayer