import { RGBColor } from "../../types/asset"

export interface Point {
    readonly x: number,
    readonly y: number,
}

export interface Size {
    readonly w: number,
    readonly h: number,
}

export class Rect {
    readonly lt: Point
    readonly rt: Point
    readonly rb: Point
    readonly lb: Point
    readonly aspectRatio: number

    constructor(readonly x: number, readonly y: number, readonly w: number, readonly h: number) {
        this.lt = { x, y }
        this.rt = { x: x + w, y }
        this.rb = { x: x + w, y: y + h }
        this.lb = { x: x, y: y + h }
        this.aspectRatio = this.w / this.h
    }
}

export type HAlign = 'left' | 'right' | 'center' | 'spacing'
export type VAlign = 'top' | 'bottom' | 'center'
export type Baseline = 'middle' | 'top' | 'bottom'

export type TextLayout = {
    readonly vAlign: VAlign
    readonly hAlign: HAlign
    readonly baseline?: Baseline
    readonly rotate?: number
    readonly archR?: number
}

export type BoxAdjust = 'inner' | 'none'

export type ImageLayout = {
    readonly adjust: BoxAdjust
    readonly vAlign: VAlign
    readonly hAlign: HAlign
    readonly rotate?: number
}

export type Transform = (ctx: CanvasRenderingContext2D) => void

export type Border = {
    readonly borderWidth: number
    readonly color: RGBColor
}
