import { FC, ReactElement, useCallback, useEffect, useState } from "react"
import { useAssets } from "./providers/assets"
import { useEditor } from "./providers/editor"
import Control from "./Control"
import Navi from "./Navi"
import { takeSnapshots } from "./preview/functions"
import Preview from "./preview/Preview"
import { useServer } from "./providers/server"
import { Order } from "../types/order"
import OrderForm from "./OrderForm"
import { getColorHexString } from "./colorUtil"

const Top: FC<Props> = ({ dataLoader }) => {
    const { server } = useServer()
    const { editor, markingImageServer, designServer } = useEditor()
    const { assets } = useAssets()
    const preRenderPeview = useCallback(async () => {
    }, [])

    const [designShareUrl, setDesignShareUrl] = useState<string|undefined>(undefined)
    const [order, setOrder] = useState<Order | null>(null)
    const [sameAddress, setSameAddress] = useState(false)

    const _handleClickOrder = useCallback(async () => {
        if (order != null) {
            return
        }
        const snapshots = await takeSnapshots(editor.design!, assets, markingImageServer, designServer, preRenderPeview)
        const snapshotFrontUrl = await server.getSnapshotUrl(snapshots[0].mediaId)
        const snapshotBackUrl = await server.getSnapshotUrl(snapshots[1].mediaId)
        const shirt = assets.getPatternAsset("shirt", editor.design!.getPatternAssetId("shirt"))
        const shirtMainColor = assets.getColor(editor.design!.colors.shirtMain)
        const shirtButtonColor = assets.getColor(editor.design!.colors.shirtSub1)
        const gara = assets.getPatternAsset('shirtNest', editor.design!.nestedPatternAssetIds.shirtNest1)!
        const garaMainColor = assets.getColor(editor.design!.colors.shirtNest1Main)
        const garaSubColor = assets.getColor(editor.design!.colors.shirtNest1Sub1)
        setOrder({
            dairitenName: '',
            dairitenTantouName: '',
            dairitenZipcode: '',
            dairitenAddress: '',
            dairitenTel: '',
            dairitenEmail: '',
            destName: '',
            destZipcode: '',
            destAddress: '',
            destTel: '',
            sizeNums: {
                S: 0,
                M: 0,
                L: 0,
                XL: 0,
                "2XL": 0,
                "3XL": 0,
                "4XL": 0,
            },
            remark: '',
            snapshotFrontUrl,
            snapshotBackUrl,
            ankenName: '',
            baseColorName: shirtMainColor!.label,
            baseColorRgb: getColorHexString(shirtMainColor!.rgb),
            buttonColorName: shirtButtonColor!.label,
            buttonColorRgb: getColorHexString(shirtButtonColor!.rgb),
            garaMainColorName: garaMainColor!.label,
            garaMainColorRgb: getColorHexString(garaMainColor!.rgb),
            garaName: gara!.label,
            garaSubColorName: garaSubColor!.label,
            garaSubColorRgb: getColorHexString(garaSubColor!.rgb),
            shirtName: shirt!.label,
        })
    }, [assets, designServer, editor.design, markingImageServer, order, preRenderPeview, server])

    const [handleClickOrder, isOrderLoading] = useOnClickOnce(_handleClickOrder)

    const [previewLoading, setPreviewLoading] = useState(false)
    const handleDraw = useCallback(() => {
        setPreviewLoading(false)
    }, [])
    const handleDrawStart = useCallback(() => {
        setPreviewLoading(true)
    }, [])

    const bgList = ["bg001"]

    const [bgIdx, setBgIdx] = useState(0)

    const previewWrapperClassName = () => {
        const a = ['simulationModel']

        a.push(`man--${editor.manType}`)
        a.push(`angle--${editor.angle}`)
        return a.join(' ')
    }

    const className = () => {
        const a = [`simulationBlock`, `${bgList[bgIdx]}`]
        if (editor.zoom === 'zoom') {
            a.push('zoom')
        }
        if (previewLoading) {
            a.push('loading--preview')
        }
        if (designShareUrl != null) {
            a.push('modal--share')
        }
        if (isOrderLoading) {
            a.push('loading--share')
        }
        return a.join(' ')
    }

    const handleSaveDesign = async () => {
        const url = await server.saveDesign(editor.design!)
        setDesignShareUrl(url)
    }

    const _handleSubmitOrder = useCallback(async () => {
        if (order == null) {
            return
        }
        if (sameAddress) {
            order.destName = order.dairitenName
            order.destZipcode = order.dairitenZipcode
            order.destAddress = order.dairitenAddress
            order.destTel = order.dairitenTel
        }
        await server.createOrder(order)
        alert('発注しました')
        setOrder(null)
    }, [order, sameAddress, server])

    const [handleSubmitOrder, isSubmitingOrder] = useOnClickOnce(_handleSubmitOrder)

    useEffect(() => {
        window.onbeforeunload = function(event){
            event = event || window.event; 
            event.returnValue = 'ページから移動しますか？';
          }
        return () => {
            window.onbeforeunload = null
        }
    }, [])



    if (order != null) {
        return <OrderForm
            order={order}
            setOrder={setOrder}
            sameAddress={sameAddress}
            setSameAddress={setSameAddress}
            onSubmit={handleSubmitOrder}
            onCancel={() => setOrder(null)}
        />
    }
    return (
        <>
            <div className={className()}>
                <header>
                    <a href="/" className="headLogo hover">
                        <img className="" alt="TEAMS" src={require('./style/images/creminaLogo.png')} />
                    </a>
                </header>
                <Navi />
                <Control />

                <div className="spinner type1">
                    <span>Loading...</span>
                </div>

                <section className="simulationCartbox">

                    <div className="btnBlock">
                        <button className="favoriteBtn" onClick={() => handleSaveDesign()}>デザインを保存する</button>
                        <button className="cartinBtn" onClick={() => handleClickOrder()}>発注する</button>
                    </div>
                </section>

                <div className="sharePopup">
                    <div className="popupBox">
                        <div className="change-time1 fadeUp">
                            <div className="closed" onClick={() => setDesignShareUrl(undefined)}>×</div>
                            <div className="title">このデザインを保存する</div>
                            <div className="text">こちらのURLにアクセスすれば、再度デザインの閲覧と編集が可能です。</div>
                            <input type="text" className="save_input_text" readOnly value={designShareUrl != null ? designShareUrl : ""} />
                        </div>
                    </div>
                </div>


                <div className="popup_000">
                </div>

                <section className={previewWrapperClassName()}>
                    <div className="test">
                        {dataLoader != null && dataLoader}
                        {dataLoader == null && (
                            <Preview
                                className="canvas modelCanvas"
                                design={editor.design!}
                                assets={assets}
                                angle={editor.angle}
                                manType={editor.manType}
                                markingImageServer={markingImageServer}
                                onDraw={handleDraw}
                                onDrawStart={handleDrawStart}
                                preRender={preRenderPeview}
                            />
                        )}
                    </div>
                    <div className="angle">
                        <div className="leftBox" onClick={() => editor.swapAngle('right')}>
                            <div className="angleLeft leftright1"></div>
                        </div>
                        <div className="rightBox" onClick={() => editor.swapAngle('left')}>
                            <div className="angleRight leftright2"></div>
                        </div>
                    </div>
                    <div className="zoomOff" onClick={() => editor.toggleZoom()}></div>
                </section>

            </div>
            {dataLoader != null && dataLoader}
        </>
    )
}

export default Top

interface Props {
    dataLoader?: ReactElement
}



const useOnClickOnce = (f: () => Promise<any>): [() => void, boolean] => {
    const [isLoading, setIsLoading] = useState(false)
    const handleClick = useCallback(async () => {
        if (isLoading) {
            return
        }
        try {
            setIsLoading(true)
            await f()
        } finally {
            setIsLoading(false)
        }
    }, [f, isLoading])
    return [handleClick, isLoading]
}
