import { FC } from "react";

export const ControlBlock: FC<ControlBlocksProps> = ({ title, titleNumber, disabled, selected, children }) => {
    const className = () => {
        const a: string[] = ["controlBlock"]
        if (disabled) {
            a.push('disabled')
        }
        if (selected) {
            a.push('selected')
        }
        return a.join(' ')
    }
    return (
        <div className={className()}>
            <h3>
                <span className="blockTitle">{title}</span>
                <span className="blockNum">{titleNumber}</span>
            </h3>
            <div className="controlBlockBottom">
                {children}
            </div>
        </div>
    )

}

export interface ControlBlocksProps {
    title: string,
    titleNumber: string,
    disabled: boolean,
    selected: boolean,
}

