import { Image } from "./Image";
import { Layer } from "./Layer";
import { ImageLayout, Rect, Size } from "./types";

class ImageLayer implements Layer {
    readonly width: number
    readonly height: number
    readonly rect: Rect
    readonly layout: ImageLayout
    private image: Image

    constructor(width: number, height: number, rect: Rect, layout: ImageLayout, url: string) {
        this.width = width
        this.height = height
        this.rect = rect
        this.layout = layout
        this.image = new Image(url)
    }

    async draw(dst: HTMLCanvasElement): Promise<void> {
        const ctx = dst.getContext('2d')!
        ctx.save()
        const { x, y, w, h } = this.getLayoutedRect()
        ctx.translate(x, y)
        if (this.layout.rotate != null) {
            ctx.translate(w/2, h/2)
            ctx.rotate(this.layout.rotate)
            ctx.translate(-(w/2), -(h/2))
        }
        ctx.drawImage(this.image.getElement(), 0, 0, w, h)
        ctx.restore()
    }

    load(): Promise<void>[] {
        return [...this.image.load()]
    }

    private getLayoutedRect(): Rect {
        const img = this.image.getElement()
        const imgSize = { w: img.width, h: img.height }
        const { w, h } = this.getLayoutSize(imgSize)
        const x = this.getLayoutX({ w, h })
        const y = this.getLayoutY({ w, h })
        return new Rect(x, y, w, h)
    }

    private getLayoutSize(imgSize: Size): Size {
        const imgAspectRatio = imgSize.w / imgSize.h
        if (this.layout.adjust === 'inner') {
            if (imgAspectRatio > this.rect.aspectRatio) {
                return {
                    w: this.rect.w,
                    h: this.rect.w / imgAspectRatio,
                }
            } else {
                return {
                    w: this.rect.h * imgAspectRatio,
                    h: this.rect.h,
                }
            }
        }
        return this.rect
    }

    private getLayoutX(dstSize: Size): number {
        switch (this.layout.hAlign) {
            case 'center':
            case 'spacing':
                return this.rect.x + (this.rect.w - dstSize.w) / 2
            case 'left':
                return this.rect.x
            case 'right':
                return this.rect.rb.x - dstSize.w
        }
    }

    private getLayoutY(dstSize: Size): number {
        switch (this.layout.vAlign) {
            case 'center':
                return this.rect.y + (this.rect.h - dstSize.h) / 2
            case 'top':
                return this.rect.y
            case 'bottom':
                return this.rect.rb.y - dstSize.h
        }
    }
}

export default ImageLayer
