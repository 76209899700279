import { ComponentType } from "react";
import Marking from "./Marking";
import NestedPatternAndColor from "./NestedPatternAndColor";
import PatternAndColor from "./PatternAndColor";
import SubCategorySelector from "./SubCategorySelector";

const Shirt: ComponentType = () => {
    return (
        <section className="simulationControl shirts">
            <div className="controlContents shirts">
                <h2>
                    <span>シャツ</span>
                    <svg version="1.1" id="&#x30EC;&#x30A4;&#x30E4;&#x30FC;_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100">
                        <g>
                            <path d="M8.475,26.331v20.302h13.498c2.307-11.096-1.317-20.938-2.792-24.286L8.475,26.331z" />
                            <path d="M77.841,21.238l-14.134-5.26C61.943,21.842,56.475,26.118,50,26.118c-6.476,0-11.943-4.276-13.706-10.139l-14.134,5.26c1.78,4.035,5.767,15.13,2.548,27.692v35.092h50.584V48.929
                C72.074,36.368,76.06,25.273,77.841,21.238z"/>
                            <path d="M80.82,22.347c-1.475,3.347-5.099,13.19-2.792,24.285h13.497V26.331L80.82,22.347z" />
                        </g>
                    </svg>
                </h2>
                <SubCategorySelector
                    list={[
                        ["shirtDesign", "シャツデザイン"],
                        ["shirtNestedPattern", "柄"],
                        ["marking", "マーキング"],
                    ]}
                />
                <PatternAndColor title="デザイン" kind="shirt" titleNumber="01" category="shirt" subCategory="shirtDesign" colorPlaceholderSelectorKind="shirt" colorPaletteTags={{shirtSub1: "button"}}/>
                <NestedPatternAndColor title="柄" kind="shirtNest" titleNumber="02" category="shirt" subCategory="shirtNestedPattern" colorPlaceholderSelectorKind="shirtNest1" placement="shirtNest1" />
                <Marking />
            </div>
        </section>
    )
}

export default Shirt
