import { Design, MarkingPlacement } from "../types/design"
import { useEditor } from "./providers/editor"
import { Angle, SubCategory } from "../types/editor"
import { ControlBlock } from "./style/editorControl"
import SubCategorySelector from "./SubCategorySelector"

const MarkingPlacementSelector = () => {
    const { editor } = useEditor()
    const handleSelectPlacement  = (placement: MarkingPlacement, angle:Angle) => {
        editor.selectMarkingPlacement(placement, angle)
    }

    const blockClassName = ({ subCateg }: { subCateg: SubCategory }) => {
        const a = ['blockinblock']
        if (subCateg === editor.selectedSubCategories[editor.category]) {
            a.push('selected')
        }
        return a.join(' ')
    }
    const placementClassName = (placement: MarkingPlacement) => {
        const a = ['markingParts']
        if (editor.design!.markings[placement] != null) {
            a.push('edited')
            switch (editor.design!.markings[placement]?.type) {
                case "image":
                    a.push('edited--image')
                    break
                case "text":
                    a.push('edited--text')
                    break
                default:
                    break
            }
        }
        return a.join(' ')
    }

    const placementProps = (placement:MarkingPlacement, angle:Angle) => ({
        onClick: () => handleSelectPlacement(placement, angle),
        className: placementClassName(placement),
    })
    return (
        <>


            <div>
                <div className={blockClassName({ subCateg: 'marking' })}>
                    <h4>シャツ正面</h4>
                    <div className="markingBox">
                        <svg className="shirtsMark" version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 330 400">

                            <defs>
                                <clipPath id="clippath">
                                    <path className="cls-2" d="M44.18,148.92c-11.4-4.28-29.27-20.07-39.81-33.55,0,0,65.3-76.14,77.64-84.81,4.17,21.34-2.11,89.6-15.94,112.52-13.35,2.6-10.94,1.77-21.89,5.85Z"/>
                                </clipPath>
                                <clipPath id="clippath-1">
                                    <path className="cls-2" d="M285.17,148.77c11.4-4.28,29.27-20.07,39.81-33.55,0,0-65.3-76.14-77.64-84.81-4.17,21.34,2.11,89.6,15.94,112.52,13.35,2.6,10.94,1.77,21.89,5.85Z"/>
                                </clipPath>
                            </defs>

                            <g className="bodyparts">
                                <rect className="cls-7" x="137.95" y="13.18" width="45.01" height="42.59"/>
                                <path className="cls-7" d="M164.56,383.93c30.43-.15,85.44-16.4,98.84-23.17,0,.19-48.61,4.34-100.17,4.34s-97.28-4.1-97.26-4.34c13.4,6.77,68.41,23.01,98.84,23.17"/>
                                <path className="cls-5" d="M183.59,13.31s-9.41-1.01-19.28-1.01-18.54,1.01-18.54,1.01l-.48-1.15s9.55-1.17,19.24-1.17,19.65,1.17,19.65,1.17l-.59,1.15Z"/>
                                <g>
                                    <path className="cls-10" d="M139.45,.81C148.26-.16,158.68,.01,164.81,.01h-.25c6.13,0,16.54-.17,25.35,.8l-9.38,16.56c-4.24-.65-9.52-1.32-15.85-1.32s-11.96,.41-16.2,1.06L139.45,.81Z"/>
                                    <path className="cls-2" d="M165.61,36.34c24.3,1.4,29.57-3.64,34.85-21.1-2.33-3.33-8.97-12.67-10.55-13.61-4.41,8.01-13.22,26.57-24.3,34.71h-1.86c-11.08-8.14-19.89-26.7-24.3-34.71-1.57,.94-8.21,10.29-10.55,13.61,5.27,17.46,10.55,22.5,34.85,21.1"/>
                                </g>
                                <g>
                                    <path className="cls-1" d="M44.18,148.92c-11.4-4.28-29.27-20.07-39.81-33.55,0,0,65.3-76.14,77.64-84.81,4.17,21.34-2.11,89.6-15.94,112.52-13.35,2.6-10.94,1.77-21.89,5.85Z"/>
                                    <g className="cls-12">
                                        <path className="cls-2" d="M30.1,129.01c11.82,10.02,24.72,17.06,24.72,17.06l-9.7,13.25L.81,112.94l9.22-4.99s8.38,11.16,20.06,21.06Z"/>
                                    </g>
                                    <path className="cls-6" d="M44.18,148.92c-11.4-4.28-29.27-20.07-39.81-33.55,0,0,65.3-76.14,77.64-84.81,4.17,21.34-2.11,89.6-15.94,112.52-13.35,2.6-10.94,1.77-21.89,5.85Z"/>
                                </g>
                                <line className="cls-3" x1="155.77" y1="125.31" x2="155.77" y2="37.74"/>
                                <path className="cls-2" d="M66.31,143.08c9.08,50.93,.35,166.89-.35,217.69,0,0,40.57,4.35,98.84,4.35h-.25c58.27,0,98.84-4.35,98.84-4.35-.7-50.8-9.43-166.76-.35-217.69-13.84-22.91-20.11-91.18-15.94-112.52-8.94-7.7-36.52-9.92-46.65-15.32-5.27,17.46-10.55,22.5-34.85,21.1h-1.86c-24.3,1.4-29.57-3.64-34.85-21.1h-.25c-10.13,5.4-37.71,7.61-46.65,15.32,4.17,21.34-2.11,89.6-15.94,112.52"/><circle className="cls-4" cx="164.68" cy="43.52" r="3.2"/>
                                <circle className="cls-4" cx="164.68" cy="69.55" r="3.2"/>
                                <circle className="cls-4" cx="164.68" cy="97.05" r="3.2"/>
                                <path className="cls-11" d="M66.68,349.11c13.59,1.12,48.06,3.14,62.59,2.51v-85.82l-59.8,8.29"/>
                                <path className="cls-11" d="M262.64,349.11c-13.59,1.12-48.06,3.14-62.59,2.51v-85.82l59.8,8.29"/>
                                <g>
                                    <path className="cls-6" d="M189.91,94.15v43.14c-.25,9.02,7.72,9.02,9.87,9.02h25.58c8.07,0,6.98-4.48,7.26-10.95v-49.39l-42.72,8.18Z"/>
                                    <path className="cls-6" d="M232.75,99.58c-14.28,2.25-28.56,4.5-42.83,6.75v-12.18c14.24-2.73,28.48-5.46,42.72-8.18l.12,13.62Z"/>
                                </g>
                                <g>
                                    <path className="cls-1" d="M285.17,148.77c11.4-4.28,29.27-20.07,39.81-33.55,0,0-65.3-76.14-77.64-84.81-4.17,21.34,2.11,89.6,15.94,112.52,13.35,2.6,10.94,1.77,21.89,5.85Z"/>
                                    <g className="cls-9">
                                        <path className="cls-2" d="M299.25,128.85c-11.82,10.02-24.72,17.06-24.72,17.06l9.7,13.25,44.3-46.38-9.22-4.99s-8.38,11.16-20.06,21.06Z"/>
                                    </g>
                                    <path className="cls-6" d="M285.17,148.77c11.4-4.28,29.27-20.07,39.81-33.55,0,0-65.3-76.14-77.64-84.81-4.17,21.34,2.11,89.6,15.94,112.52,13.35,2.6,10.94,1.77,21.89,5.85Z"/>
                                </g>
                            </g>
                            <rect {...placementProps('shirtFront02', 'front')} id="markingB" className="cls-8 markingParts" x="179.38" y="39.79" width="57.7" height="41.91"/>
                            <rect {...placementProps('shirtFront01', 'front')} id="markingA" className="cls-8 markingParts" x="92.26" y="39.79" width="57.7" height="41.91"/>



                        </svg>

                    </div>
                </div>

                <div className={blockClassName({ subCateg: 'marking' })}>
                    <h4>シャツ背面</h4>
                    <div className="markingBox">
                    <svg className="shirtsMark" version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 330 400">

                        <defs>
                            <clipPath id="clippath">
                                <path className="cls2-2" d="M45.36,148.61c-11.4-4.28-29.27-20.07-39.81-33.55,0,0,62.19-74.05,74.53-82.72,3.87,54.25-.88,98.62-12.83,110.42-13.35,2.6-10.94,1.77-21.88,5.85Z"/>
                            </clipPath>
                            <clipPath id="clippath-1">
                                <path className="cls2-2" d="M286.38,148.82c11.4-4.28,29.27-20.07,39.81-33.55,0,0-62.19-74.05-74.53-82.72-3.87,54.25,.88,98.62,12.83,110.42,13.35,2.6,10.94,1.77,21.88,5.85Z"/>
                            </clipPath>
                        </defs>

                        <g>
                            <path className="cls2-2" d="M165.78,18.93c9.21,.4,30.06-.74,38.67-2.75l-13.31-14.87c-8.81-.97-19.22-.8-25.35-.8s-16.54-.17-25.35,.8l-13.31,14.87c8.61,2,29.46,3.15,38.67,2.75Z"/>
                            <path className="cls2-2" d="M264.63,360.37c0-30.55-.99-193.23-.18-217.61-11.95-11.81-16.81-56.13-12.94-110.37-8.94-7.7-36.93-10.8-47.06-16.2,0,0-12.08,2.75-38.78,2.75s-38.55-2.75-38.55-2.75c-10.13,5.4-38.12,8.5-47.06,16.2,3.87,54.25-.82,98.57-12.77,110.37,.81,24.38-.35,187.06-.35,217.61v.07c13.4,6.77,68.41,23.01,98.84,23.17,30.43-.15,85.44-16.4,98.84-23.17"/>
                            <path className="cls2-5" d="M67.42,349.32c8.46,.67,18.79,0,18.79,0l-5.69-71.03-12.82-3.37"/>
                            <path className="cls2-5" d="M264.33,349.32c-8.46,.67-18.79,0-18.79,0l5.69-71.03,12.82-3.37"/>
                            <g>
                                <path className="cls2-1" d="M45.36,148.61c-11.4-4.28-29.27-20.07-39.81-33.55,0,0,62.19-74.05,74.53-82.72,3.87,54.25-.88,98.62-12.83,110.42-13.35,2.6-10.94,1.77-21.88,5.85Z"/>
                                <g className="cls2-6">
                                    <path className="cls2-2" d="M30.1,128.77c8.82,8.58,24.72,17.06,24.72,17.06l-9.7,13.25L.81,112.7l9.22-4.99s8.38,11.16,20.06,21.06Z"/>
                                </g>
                                <path className="cls2-5" d="M45.36,148.61c-11.4-4.28-29.27-20.07-39.81-33.55,0,0,62.19-74.05,74.53-82.72,3.87,54.25-.88,98.62-12.83,110.42-13.35,2.6-10.94,1.77-21.88,5.85Z"/>
                            </g>
                            <g>
                                <path className="cls2-1" d="M286.38,148.82c11.4-4.28,29.27-20.07,39.81-33.55,0,0-62.19-74.05-74.53-82.72-3.87,54.25,.88,98.62,12.83,110.42,13.35,2.6,10.94,1.77,21.88,5.85Z"/>
                                <g className="cls2-4">
                                    <path className="cls2-2" d="M301.43,128.77c-8.82,8.58-24.72,17.06-24.72,17.06l9.7,13.25,44.3-46.38-9.22-4.99s-8.38,11.16-20.06,21.06Z"/>
                                </g>
                                <path className="cls2-5" d="M286.38,148.82c11.4-4.28,29.27-20.07,39.81-33.55,0,0-62.19-74.05-74.53-82.72-3.87,54.25,.88,98.62,12.83,110.42,13.35,2.6,10.94,1.77,21.88,5.85Z"/>
                            </g>
                        </g>
                        <rect {...placementProps('shirtBack01', 'back')} id="makingC" className="cls2-3 markingParts" x="118.09" y="26.35" width="95.56" height="35.72"/>
                        <rect {...placementProps('shirtBack02', 'back')} id="markingD" className="cls2-3 markingParts" x="106.67" y="66.26" width="118.4" height="50.66"/>
                        </svg>
                    </div>
                </div>

            </div>
        </>
    )
}

export default MarkingPlacementSelector



