import './style/style.css'
import './style/style.kari.css'
import Top from "./Top";
import { AssetsLoader } from "./loaders/assets";
import { AssetsProvider } from "./providers/assets";
import { EditorProvider } from "./providers/editor";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoadDesignFromData from './LoadDesignFromData';
import { BundleAssetsServer } from '../bundle/assets';
import { BundleInitialDesignServer } from '../bundle/design';
import { ApiServer } from '../api/server';
import { ComponentType, PropsWithChildren, useEffect } from 'react';
import { ServerProvider } from './providers/server';
import LoadDesignFromId from './LoadDesignFromId';
import { MemServer } from '../server/mem/memserver';
import { Server } from '../types/server';

function getServer(): Server {
    if (process.env.REACT_APP_USE_TESTING_SERVER === 'yes') {
        return new MemServer()
    }
    return new ApiServer(process.env.REACT_APP_API_URL!)
}

const server = getServer()

const assetsServer = new BundleAssetsServer()
const initServer = new BundleInitialDesignServer()

const routePath = (path: string) => {
    let base = process.env.PUBLIC_URL
    if (base == null) {
        base = ''
    }
    return `${base}${path}`
}

function App() {
    return (
        <BrowserRouter>
            <ServerProvider server={server}>
                <AssetsProvider server={assetsServer}>
                    <EditorProvider server={server} designServer={server} initServer={initServer} loading={<></>}>
                        <Loader>
                            <AssetsLoader>
                                <Routes>
                                    <Route path={routePath('/di')} element={<Top dataLoader={<LoadDesignFromId />} />} />
                                    <Route path={routePath('/')} element={<Top />} />
                                </Routes>
                            </AssetsLoader>
                        </Loader>
                    </EditorProvider>
                </AssetsProvider>
            </ServerProvider>
        </BrowserRouter >
    )
}

export default App;

const Loader: ComponentType<PropsWithChildren<{}>> = ({ children }) => {
    return <>{children}</>
}
