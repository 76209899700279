import { FC } from "react"
import { useEditor } from "./providers/editor"
import { SubCategory } from "../types/editor"

const SubCategorySelector: FC<Props> = ({ list }) => {
    const { editor } = useEditor()
    const isDisabled = (subCateg: SubCategory): boolean => {
        return editor.isSubcategoryDisabled(subCateg)
    }
    const handleClick = (subCateg: SubCategory) => () => {
        if (isDisabled(subCateg)) {
            return
        }
        editor.selectSubCategory(subCateg)
    }
    const className = (subCateg: SubCategory) => {
        const a: string[] = []
        if (editor.selectedSubCategories[editor.category] === subCateg) {
            a.push("selected")
        }
        if (isDisabled(subCateg)) {
            a.push('disabled')
        }
        return a.join(' ')
    }
    return (
        /*
        <ul className="ver1">
            {list.map(([subCateg, label]) => (
                <li key={subCateg} className={className(subCateg)} onClick={handleClick(subCateg)}>{label}</li>
            ))}
        </ul>   
        <nav className="ver2 clr">
        {list.map(([subCateg, label]) => (
            <Fragment key={subCateg}>
                <div key={subCateg} className={className(subCateg)} onClick={handleClick(subCateg)}>{label}</div>
            </Fragment>
        ))}
        <span className="slider"><span className="bar"></span></span>
        </nav>
        */
        <nav className="ver2 clr" id={editor.category}>
            {list.map(([subCateg, label]) => (
                <div key={subCateg} className={className(subCateg)} onClick={handleClick(subCateg)} id={subCateg}>{label}</div>
            ))}
            <span className="clear"></span>
            <span className="slider"><span className="bar"></span></span>
        </nav>
    )
}

export default SubCategorySelector

type Props = {
    list: [SubCategory, string][],
}
