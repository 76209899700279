import { ColorPlaceholder, InnerShirtPrintPlacement, InnerShirtType, MarkingPlacement, PantsLogo, PantsNumberBorderType, PantsNumberPlacement, PersonalNamePlacement, ShirtBackNumberPlacement, ShirtFrontNumberPlacement, ShirtLogo, ShirtNumberBorderType, SocksPrintPlacement } from "../types/design";

export const colorPlaceholderLabels: { [key in ColorPlaceholder]: string } = {
    shirtMain: "ベース",
    shirtSub1: "ボタン",
    eriMain: "襟",
    eriSub1: "サブ",
    pantsMain: "メイン",
    pantsSub1: "サブ1",
    pantsSub2: "サブ2",
    pantsSub3: "サブ3",
    pantsSub4: "サブ4",
    innerShirtMain: "メイン",
    innerShirtPrint: "プリント",
    socksMain: "メイン",
    socksSub1: "サブ1",
    socksSub2: "サブ2",
    socksPrint: '刺繍',
    shirtNumberFront: 'シャツ番号',
    shirtNumberBack: 'シャツ番号',
    shirtNumberBorder1: '枠色',
    pantsNumber: 'パンツ番号',
    pantsNumberBorder1: '枠色',
    personalName: '個人名',
    shirtNest1Main: '柄ベース',
    shirtNest1Sub1: '柄サブ',
}

export const innerShirtTypeLabels: { [key in InnerShirtType]: string } = {
    none: 'インナーウェアなし',
    inner1: 'インナーウェアあり',
}

export const innerShirtPrintPlacementLabels: { [key in InnerShirtPrintPlacement]: string } = {
    none: 'なし',
    mune: '正面：左胸',
    koshi: '正面：左腰',
    ude: '左肩',
}

export const shirtFrontNumberPlacementLabels: { [key in ShirtFrontNumberPlacement]: string } = {
    center: '中央胸',
    right: '右胸',
    left: '左胸',
    hara: '腹',
    none: 'なし',
}

export const shirtBackNumberPlacementLabels: { [key in ShirtBackNumberPlacement]: string } = {
    back: 'あり',
    none: 'なし'
}

export const pantsNumberPlacementLabels: { [key in PantsNumberPlacement]: string } = {
    left: '正面左もも',
    right: '正面右もも',
    none: 'なし',
}

export const personalNamePlacementLabels: {[key in PersonalNamePlacement]:string} = {
    bottom: '背中下部',
    top: '背中上部',
    topArch: '背中上部(アーチ)',
    none: 'なし',
}

export const socksPrintPlacementLabels: { [key in SocksPrintPlacement]: string } = {
    none: '刺繍なし',
    socksPrint1: '正面(すね側) ',
    socksPrint2: '背面(ふくらはぎ)'
}

export const borderTypeLabels: {[key in ShirtNumberBorderType | PantsNumberBorderType]: string} = {
    border1: '番号枠あり',
    none: '番号枠なし'
}

export const markingPlacementLabels: {[key in MarkingPlacement]:string} = {
    shirtFront01: '01-右胸上',
    shirtFront02: '02-左胸上',
    shirtFront03: '03-右胸正方形',
    shirtFront04: '04-中央胸正方形',
    shirtFront05: '05-左胸正方形',
    shirtFront06: '06-みぞおち',
    shirtFront07: '07-腹部上',
    shirtFront08: '08-腹部右下',
    shirtFront09: '09-腹部左下',
    shirtFront10: '10-下腹部',
    shirtFront11: '11-右腕肩側上',
    shirtFront12: '12-右腕肩側下',
    shirtFront13: '13-右腕内側',
    shirtFront14: '14-左腕肩側上',
    shirtFront15: '15-左腕肩側下',
    shirtFront16: '16-左腕内側',
    shirtBack01: '17-背中首',
    shirtBack02: '18-背中上段',
    shirtBack03: '19-背中中段',
    shirtBack04: '20-背中下段',
    shirtBack05: '21-左腰上段',
    shirtBack06: '22-右腰上段',
    shirtBack07: '23-左腰下段',
    shirtBack08: '24-右腰下段',
    shirtBack09: '25-腰最下段',
    shirtBack09_2: '26-シャツ裾',
    shirtBack10: '14-左腕肩側上',
    shirtBack11: '15-左腕肩側下',
    shirtBack12: '11-右腕肩側上',
    shirtBack13: '12-右腕肩側下',
    pantsFront01: 'A-右ふともも上',
    pantsFront02: 'B-右ふともも下',
    pantsFront03: 'C-左ふともも上',
    pantsFront04: 'D-左ふともも下',
    pantsBack01: 'E-左ふともも裏',
    pantsBack02: 'F-右ふともも裏',
}

export const shirtLogoLabels: {[key in ShirtLogo]: string} = {
    leftWhite: '左胸・白',
    leftBlack: '左胸・黒',
    centerWhite: '胸中央・白',
    centerBlack: '胸中央・黒',
    rightBlack: '右胸・黒',
    rightWhite: '右胸・白',
}

export const pantsLogoLabels: {[ke in PantsLogo]: string} = {
    leftFrontBlack: '左前・黒',
    leftFrontWhite: '左前・白',
    rightFrontBlack: '右前・黒',
    rightFrontWhite: '右前・白',
    leftBackBlack: '左後ろ・黒',
    leftBackWhite: '左後ろ・白',
    rightBackBlack: '右後ろ・黒',
    rightBackWhite: '右後ろ・白',
}