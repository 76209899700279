import { Loader } from "./Loader"

export class Image implements Loader {
    readonly url: string
    private element: HTMLImageElement | null

    constructor(url: string) {
        this.url = url
        this.element = null
    }

    getElement(): HTMLImageElement {
        if (this.element == null) {
            throw Error("element is null")
        }
        return this.element
    }

    load(): Promise<void>[] {
        const elem = document.createElement('img')
        elem.src = this.url
        this.element = elem
        return [
            new Promise<void>(resolve => {
                elem.addEventListener('load', () => {
                    resolve()
                })
            })
        ]
    }
}