import { FC, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Layer } from "./Layer";

export const Canvas: FC<CanvasProps> = ({ className, layer, onDraw, onDrawStart, preRender }) => {
    const ref = useRef<HTMLCanvasElement | null>(null)
    const renderLayer = useCallback(async () => {
        if (onDrawStart != null) {
            onDrawStart()
        }
        const canvas = ref.current
        if (canvas == null) {
            return
        }
        await preRender()
        await Promise.all(layer.load())
        await layer.draw(canvas)
        if (onDraw) {
            onDraw(ref.current!)
        }
    }, [layer, onDraw, onDrawStart, preRender])
    const refCallback = useCallback(elem => {
        ref.current = elem
    }, [])
    useLayoutEffect(() => {
        renderLayer()
    }, [renderLayer])
    return (
        <canvas
            className={className}
            width={layer.width}
            height={layer.height}
            ref={refCallback}></canvas>
    )
}

interface CanvasProps {
    className: string
    layer: Layer,
    onDrawStart?: () => any,
    onDraw?: (canvas: HTMLCanvasElement) => any,
    preRender: () => Promise<any>
}
