import { ComponentType } from "react";
import { ColorAsset, RGBColor } from "../types/asset";
import { useAssets } from "./providers/assets";
import { useEditor } from "./providers/editor";
import { ColorPlaceholder } from "../types/design";
import { colorPlaceholderLabels } from "./strings";

const tinycolor = require('tinycolor2')

const ColorPalette: ComponentType<Props> = ({ colorPlaceholder , visible, enable, tag}) => {
    const { editor } = useEditor()
    const { assets } = useAssets()
    if (!visible) {
        return <></>
    }
    const handleClickColor = (colorId: string) => () => {
        editor.changeColor(colorPlaceholder, colorId)
    }
    const selectedColorId = editor.design!.colors[colorPlaceholder]
    const itemClassName = (item:ColorAsset) => {
        const a = ['colorRound']
        if (item.id === selectedColorId) {
            a.push('select')
        }
        return a.join(' ')
    }
    const paletteClassName = () => {
        const a = ['colorPalette']
        if (!enable) {
            a.push('disable')
        }
        return a.join(' ')
    }
    const renderSelectedColorLabel = () => {
        const found = assets.colors.find(color => color.id === selectedColorId)
        if (found == null) {
            return ''
        }
        return found.label
    }
    const filterColor = (v:ColorAsset):boolean => {
        if (tag == null || tag === "") {
            return true
        }
        if (v.tags.includes(tag)) {
            return true
        }
        return false
    }
    return (
        <>
            <div className={paletteClassName()}>
                <div className="paletteTop">
                    <div className="pleaseColorSelect">{colorPlaceholderLabels[colorPlaceholder]}の色を選んでください。【{renderSelectedColorLabel()}】</div>
                    <div className="paletteDelete" onClick={() => editor.clearSelectedColorPlaceholder()}></div>
                </div>
                <div className="paletteColorList clr">
                    {assets.colors.filter(filterColor).map(item => (
                        <div
                            key={item.id}
                            className={itemClassName(item)}
                            onClick={handleClickColor(item.id)}
                        >
                            <span className="">
                                <span
                                    className="color01"
                                    style={{
                                        backgroundColor: getRGBColorString(item.rgb),
                                    }}
                                >
                                    <span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ColorPalette

const getRGBColorString = (rgb: RGBColor): string => {
    return tinycolor({ r: rgb.r, g: rgb.g, b: rgb.b }).toRgbString()
}

interface Props {
    colorPlaceholder: ColorPlaceholder
    enable: boolean
    visible: boolean
    tag?: string
}
