import { MouseEvent, useCallback, useEffect, useState } from "react"
import { useEditor } from "./providers/editor"
import { MarkingImage } from "../types/editor"
import MarkingImageUpload from "./MarkingImageUpload"
import { Marking } from "../types/design"

const MarkingImageInput = () => {
    const { editor, getMarkingImage } = useEditor()
    const marking = editor.design!.markings[editor.selectedMarkingPlacement!]
    const getCurrentMarkingImageId = useCallback((marking: Marking | undefined) => {
        if (marking == null) {
            return undefined
        }
        if (marking.imageId == null) {
            return undefined
        }
        return marking.imageId!
    }, [])
    const currentImageId = getCurrentMarkingImageId(marking)
    const [currentImage, setCurrentImage] = useState<MarkingImage | undefined>(undefined)
    const fetchCurrentImageAsync = useCallback(async () => {
        if (currentImageId != null) {
            setCurrentImage(await getMarkingImage(currentImageId))
        }
    }, [currentImageId, getMarkingImage])
    useEffect(() => {
        fetchCurrentImageAsync()
    }, [fetchCurrentImageAsync])
    const [imageId, setImageId] = useState<string | undefined>(() => {
        if (marking == null) {
            return undefined
        }
        return marking.imageId
    })
    const [image, setImage] = useState<MarkingImage | undefined>(undefined)
    const fetchImageAsync = useCallback(async () => {
        if (imageId == null) {
            setImage(undefined)
            return
        }
        setImage(await getMarkingImage(imageId!))
    }, [getMarkingImage, imageId])
    useEffect(() => {
        fetchImageAsync()
    }, [fetchImageAsync])
    const handleUpload = (result: MarkingImage) => {
        setImageId(result.id)
    }
    const handleClickSave = async () => {
        if (imageId == null) {
            return
        }
        editor.setMarkingImageId(editor.selectedMarkingPlacement!, imageId)
        setImageId(undefined)
    }
    const handleClickBack = () => {
        editor.clearMarkingSelect()
    }
    const handleClickOverlay = (evt: MouseEvent<HTMLDivElement>) => {
        evt.preventDefault()
        handleClickBack()
    }
    const handleClickOverlayChildren = (evt: MouseEvent<HTMLDivElement>) => {
        // evt.preventDefault()
        evt.stopPropagation()
    }
    const handleClickDelete = () => {
        setImage(undefined)
        setCurrentImage(undefined)
        editor.deleteMarking(editor.selectedMarkingPlacement!)
    }
    const render = () => {
        if (currentImageId != null) {
            return (
                <>
                    <div className="upImgBox">
                        {currentImage != null && (
                            <img src={currentImage.url} alt="" style={{ width: 200 }} />
                        )}
                        <button className="fileHanei" type="button" onClick={handleClickDelete}>削除</button>
                    </div>
                </>
            )
        }
        return (
            <>
                <MarkingImageUpload onUpload={handleUpload} />

                <div className="upImgBox">
                    {image && (
                        <img src={image.url} alt="" style={{ width: 200 }} />
                    )}
                    <button className="fileHanei" type="button" onClick={handleClickSave}>この画像を反映する</button>
                </div>
            </>
        )
    }
    return (
        <div className="markingTypeSlectBlock" onClick={handleClickOverlay}>

            <div className="fileUpload popupNormalBlock" onClick={handleClickOverlayChildren}>
                <div className="markingFileUploadTop">
                    <div className="title">マーキング画像のアップロード</div>
                    <div className="text">アップロードできるファイルはjpg、png、gifの画像形式のみです。<br />
                        ファイルサイズは○○MBまでとなります。<br />
                        ロゴ等のファイルの背景部分を透明にしたい場合は、あらかじめ透過処理をしたpngをご用意ください<div className=""></div>

                    </div>
                </div>
                {render()}
                <button className="markingRtn" type="button" onClick={handleClickBack}>戻る</button>
            </div>
        </div>
    )
}

export default MarkingImageInput
