import { Assets } from "./asset"
import { ColorPlaceholder, Design, InnerShirtPrintPlacement, InnerShirtType, MarkingPlacement, MarkingText, MarkingType, NestedPatternPlacement, PantsLogo, PantsNumberBorderType, PantsNumberPlacement, PatternsKind, PersonalNamePlacement, ShirtBackNumberPlacement, ShirtFrontNumberPlacement, ShirtLogo, ShirtNumberBorderType, SocksPrintPlacement, TextKind } from "./design"

export class Editor {
    private readonly dispatch: EditorDispatch
    readonly design: Design | undefined
    readonly category: Category
    readonly selectedColorPlaceholder: ColorPlaceholder | undefined
    readonly savedManType: ManType
    readonly manType: ManType
    readonly angle: Angle
    readonly zoom: Zoom
    readonly selectedMarkingPlacement: MarkingPlacement | undefined
    readonly selectedMarkingType: MarkingType | undefined
    readonly selectedMarkingAngle: Angle | undefined
    readonly selectedSubCategories: { [key in Category]: SubCategory }

    constructor(dispatch: EditorDispatch, arg: {
        design: Design | undefined
        category: Category,
        manType: ManType,
        savedManType: ManType,
        angle: Angle,
        zoom: Zoom,
        selectedColorPlaceholder: ColorPlaceholder | undefined,
        selectedMarkingPlacement: MarkingPlacement | undefined,
        selectedMarkingType: MarkingType | undefined,
        selectedMarkingAngle: Angle | undefined,
        selectedSubCategories: { [key in Category]: SubCategory }
    }) {
        this.dispatch = dispatch
        this.design = arg.design
        this.category = arg.category
        this.manType = arg.manType
        this.savedManType = arg.savedManType
        this.angle = arg.angle
        this.zoom = arg.zoom
        this.selectedColorPlaceholder = arg.selectedColorPlaceholder
        this.selectedMarkingPlacement = arg.selectedMarkingPlacement
        this.selectedMarkingType = arg.selectedMarkingType
        this.selectedMarkingAngle = arg.selectedMarkingAngle
        this.selectedSubCategories = arg.selectedSubCategories
    }

    isSelectedColorPlaceholderIn(colorPlaceholders: ColorPlaceholder[]): boolean {
        if (this.selectedColorPlaceholder == null) {
            return false
        }
        return colorPlaceholders.includes(this.selectedColorPlaceholder)
    }

    isColorPaletteVisible(kind: ColorPlaceholderSelectorKind): boolean {
        if (this.selectColorPlaceholder == null) {
            return false
        }
        const l = this.getColorPlaceholders(kind)
        return l.includes(this.selectedColorPlaceholder!)
    }

    /*
    changeManType(manType: ManType): void {
        return this.dispatch(new Editor(this.dispatch, { ...this, manType, savedManType: manType }))
    }
    */

    swapManType(): void {
        switch (this.angle) {
            case 'front':
            case 'back':
                switch (this.manType) {
                    case 'man01':
                        this.dispatch(new Editor(this.dispatch, { ...this, manType: 'man02', savedManType: 'man02' }))
                        break
                    case 'man02':
                        this.dispatch(new Editor(this.dispatch, { ...this, manType: 'man01', savedManType: 'man01' }))
                        break
                }
                return
            case 'hira':
                switch (this.savedManType) {
                    case 'man01':
                        this.dispatch(new Editor(this.dispatch, { ...this, savedManType: 'man02' }))
                        break
                    case 'man02':
                        this.dispatch(new Editor(this.dispatch, { ...this, savedManType: 'man01' }))
                        break
                }
        }
    }

    /*
    changeAngle(angle: Angle): void {
    }
    */

    swapAngle(direction: AngleSwapDirection): void {
        switch (direction) {
            case 'left':
                switch (this.angle) {
                    case 'front':
                        this.dispatch(new Editor(this.dispatch, { ...this, angle: 'hira', manType: 'man02' }))
                        break
                    case 'back':
                        this.dispatch(new Editor(this.dispatch, { ...this, angle: 'hira', manType: 'man01' }))
                        break
                    case 'hira':
                        switch (this.manType) {
                            case 'man01':
                                this.dispatch(new Editor(this.dispatch, { ...this, angle: 'front', manType: this.savedManType }))
                                break
                            case 'man02':
                                this.dispatch(new Editor(this.dispatch, { ...this, angle: 'back', manType: this.savedManType }))
                                break
                        }
                }
                break
            case 'right':
                switch (this.angle) {
                    case 'front':
                        this.dispatch(new Editor(this.dispatch, { ...this, angle: 'hira', manType: 'man01' }))
                        break
                    case 'back':
                        this.dispatch(new Editor(this.dispatch, { ...this, angle: 'hira', manType: 'man02' }))
                        break
                    case 'hira':
                        switch (this.manType) {
                            case 'man01':
                                this.dispatch(new Editor(this.dispatch, { ...this, angle: 'back', manType: this.savedManType }))
                                break
                            case 'man02':
                                this.dispatch(new Editor(this.dispatch, { ...this, angle: 'front', manType: this.savedManType }))
                                break
                        }
                }
        }

    }

    changeZoom(zoom: Zoom): void {
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            zoom,
        }))
    }

    toggleZoom(): void {
        switch (this.zoom) {
            case 'none':
                this.changeZoom('zoom')
                return
            case 'zoom':
                this.changeZoom('none')
                return
        }
    }

    changeCategory(category: Category): void {
        this.dispatch(new Editor(this.dispatch, { ...this, category }))
    }

    selectColorPlaceholder(colorPlaceholderId: string): void {
        this.dispatch(new Editor(this.dispatch, { ...this, selectedColorPlaceholder: colorPlaceholderId }))
    }

    clearSelectedColorPlaceholder(): void {
        this.dispatch(new Editor(this.dispatch, { ...this, selectedColorPlaceholder: undefined }))
    }

    selectMarkingPlacement(placement: MarkingPlacement, angle: Angle): void {
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            selectedMarkingPlacement: placement,
            selectedMarkingType: 'image',
            selectedMarkingAngle: angle,
        }))
    }

    selectMarkingType(t: MarkingType): void {
        if (this.selectedMarkingPlacement === undefined) {
            return
        }
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            selectedMarkingType: t,
        }))
    }

    clearMarkingSelect(): void {
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            selectedMarkingPlacement: undefined,
            selectedMarkingType: undefined,
            selectedMarkingAngle: undefined,
        }))
    }

    clearMarkingTypeSelect(): void {
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            selectedMarkingType: undefined,
        }))
    }

    isSubcategoryDisabled(subcateg: SubCategory): boolean {
        switch (subcateg) {
            case "shirtDesign":
                return false
            case "shirtNestedPattern":
                return false
            case "shirtEriSelect":
                return false
            case "shirtLogo":
                return false
            case "pantsDesign":
                return false
            case "pantsLogo":
                return false
            case "socksDesign":
                return false
            case "socksSisyuPos":
                return false
            case "socksSisyuFont":
                return !this.design!.hasSocksPrint()
            case "innerShirtDesign":
                return false
            case "innerShirtPrint":
                return !this.design!.hasInnerShirt()
            case "innerShirtPrintFont":
                return !this.design!.hasInnerShirtPrint()
            case "numberShirtFrontPos":
                return false
            case "numberShirtBackPos":
                return false
            case "numberPantsPos":
                return false
            case "numberBorder":
                return !this.design!.hasNumber()
            case "numberFont":
                return !this.design!.hasNumber()
            case "personalNamePos":
                return false
            case "personalNameFont":
                return !this.design!.hasPersonalName()
            case "marking":
                return false
        }
    }

    selectSubCategory(subcateg: SubCategory) {
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            selectedSubCategories: {
                ...this.selectedSubCategories,
                [this.category]: subcateg,
            },
        }))
    }

    getColorPlaceholders(kind: ColorPlaceholderSelectorKind): ColorPlaceholder[] {
        switch (kind) {
            case 'shirt':
                return ['shirtMain', 'shirtSub1']
            case 'pants':
                return ['pantsMain', 'pantsSub1', 'pantsSub2', 'pantsSub3', 'pantsSub4']
            case 'socks':
                return ['socksMain', 'socksSub1', 'socksSub2']
            case 'socksPrint':
                return ['socksPrint']
            case 'eri':
                return ['eriMain', 'eriSub1']
            case 'innerShirt':
                return ['innerShirtMain']
            case 'innerShirtPrint':
                return ['innerShirtPrint']
            case 'shirtNumberFront':
                return ['shirtNumberFront']
            case 'shirtNumberBack':
                return ['shirtNumberBack']
            case 'shirtNumberBorder':
                return ['shirtNumberBorder1']
            case 'pantsNumber':
                return ['pantsNumber']
            case 'pantsNumberBorder':
                return ['pantsNumberBorder1']
            case 'personalName':
                return ['personalName']
            case 'shirtNest1':
                return ['shirtNest1Main', 'shirtNest1Sub1']
        }
    }



    // DESIGN

    changePattern(kind: PatternsKind, assetId: string): void {
        this.updateDesign(this.design!.changePattern(kind, assetId))
    }

    changeNestedPattern(placement: NestedPatternPlacement, assetId: string): void {
        this.updateDesign(this.design!.changeNestedPattern(placement, assetId))
    }

    changeColor(colorPlaceholder: ColorPlaceholder, colorId: string): void {
        this.updateDesign(this.design!.changeColor(colorPlaceholder, colorId))
    }

    changeInnerShirtType(innerShirtType: InnerShirtType): void {
        this.updateDesign(this.design!.changeInnerShirtType(innerShirtType))
    }

    changeInnerShirtPrintPlacement(innerShirtPrintPlacement: InnerShirtPrintPlacement): void {
        this.updateDesign(this.design!.changeInnerShirtPrintPlacement(innerShirtPrintPlacement))
    }

    changeSocksPrintPlacement(socksPrintPlacement: SocksPrintPlacement): void {
        const design = this.design!.changeSocksPrintPlacement(socksPrintPlacement)
        let angle: Angle
        if (!design.isSocksPrintVisible()) {
            angle = this.angle
        } else if (design!.isFrontVisibleSocksPrint()) {
            angle = 'front'
        } else {
            angle = 'back'
        }
        this.dispatch(new Editor(this.dispatch, { ...this, design, angle }))
    }

    changeShirtFrontNumberPlacement(shirtFrontNumberPlacement: ShirtFrontNumberPlacement): void {
        const design = this.design!.changeShirtFrontNumberPlacement(shirtFrontNumberPlacement)
        return this.dispatch(new Editor(this.dispatch, { ...this, design, angle: 'front' }))
    }

    changeShirtBackNumberPlacement(shirtBackNumberPlacement: ShirtBackNumberPlacement): void {
        const design = this.design!.changeShirtBackNumberPlacement(shirtBackNumberPlacement)
        return this.dispatch(new Editor(this.dispatch, { ...this, design, angle: 'back' }))
    }

    changePantsNumberPlacement(pantsNumberPlacement: PantsNumberPlacement): void {
        const design = this.design!.changePantsNumberPlacement(pantsNumberPlacement)
        return this.dispatch(new Editor(this.dispatch, { ...this, design, angle: 'front' }))
    }

    changePersonalNamePlacement(personalNamePlacement: PersonalNamePlacement): void {
        const design = this.design!.changePersonalNamePlacement(personalNamePlacement)
        return this.dispatch(new Editor(this.dispatch, { ...this, design, angle: 'back' }))
    }

    changeShirtNumberBorderType(shirtNumberBorderType: ShirtNumberBorderType): void {
        this.updateDesign(this.design!.changeShirtNumberBorderType(shirtNumberBorderType))
    }

    changePantsNumberBorderType(pantsNumberBorderType: PantsNumberBorderType): void {
        this.updateDesign(this.design!.changePantsNumberBorderType(pantsNumberBorderType))
    }

    changeShirtLogo(shirtLogo: ShirtLogo): void {
        const design = this.design!.changeShirtLogo(shirtLogo)
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            design,
            angle: 'front',
            manType: this.savedManType,
        }))
    }

    changePantsLogo(pantsLogo: PantsLogo): void {
        const design = this.design!.changePantsLogo(pantsLogo)
        const angle = design.isFrontVisiblePantsLogo() ? 'front' : 'back'
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            design,
            angle,
            manType: this.savedManType,
        }))
    }

    changeText(textKind: TextKind, text: string): void {
        this.updateDesign(this.design!.changeText(textKind, text))
    }

    changeFontId(textKind: TextKind, fontId: string): void {
        this.updateDesign(this.design!.changeFontId(textKind, fontId))
    }

    setMarkingText(placement: MarkingPlacement, markingText: MarkingText): void {
        const design = this.design!.setMarkingText(placement, markingText)
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            design,
            angle: this.selectedMarkingAngle,
            manType: this.savedManType,
            selectedMarkingPlacement: undefined,
            selectedMarkingType: undefined,
            selectedMarkingAngle: undefined,
        }))
    }

    setMarkingImageId(placement: MarkingPlacement, markingImageId: string): void {
        const design = this.design!.setMarkingImageId(placement, markingImageId)
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            design,
            angle: this.selectedMarkingAngle,
            manType: this.savedManType,
            selectedMarkingPlacement: undefined,
            selectedMarkingType: undefined,
            selectedMarkingAngle: undefined,
        }))
    }

    deleteMarking(placement: MarkingPlacement): void {
        const design = this.design!.deleteMarking(placement)
        this.dispatch(new Editor(this.dispatch, {
            ...this,
            design,
        }))
    }

    // DESIGN

    getAllJPCharacters(asset: Assets): string {
        const sDesign = this.design != null ? this.design?.getAllTextCharacters() : ''
        const s = `${asset.getFontSampleJpStringCharacters()}${sDesign}`
        const o: { [key in string]: true } = {}
        for (let i = 0; i < s.length; i++) {
            o[s[i]] = true
        }
        return Object.keys(o).sort().join('')
    }

    private updateDesign(design: Design): void {
        this.dispatch(new Editor(this.dispatch, { ...this, design }))
    }
}

export type EditorDispatch = (editor: Editor) => void

export const mainCategories = ["shirt"] as const

export type Category = typeof mainCategories[number]

export const subCategories = [
    "shirtDesign",
    "shirtNestedPattern",
    "shirtEriSelect",
    "shirtLogo",
    "pantsDesign",
    "pantsLogo",
    "socksDesign",
    "socksSisyuPos",
    "socksSisyuFont",
    "innerShirtDesign",
    "innerShirtPrint",
    "innerShirtPrintFont",
    "numberShirtFrontPos",
    "numberShirtBackPos",
    "numberPantsPos",
    "numberBorder",
    "numberFont",
    "personalNamePos",
    "personalNameFont",
    "marking",
] as const

export type SubCategory = typeof subCategories[number]

export const angles = ["front", "back", "hira"] as const

export type Angle = typeof angles[number]

export const manTypes = ["man01", "man02"] as const

export type ManType = typeof manTypes[number]

export interface MarkingImage {
    id: string
    url: string
}

export interface Snapshot {
    manType: ManType,
    angle: Angle,
    mediaId: string
}

export const zooms = ['none', 'zoom'] as const

export type Zoom = typeof zooms[number]

export const colorPlaceholderSelectorKinds = ['shirt', 'pants', 'socks', 'innerShirt', 'innerShirtPrint', 'socksPrint', 'shirtNumberFront', 'shirtNumberBack', 'shirtNumberBorder', 'pantsNumber', 'pantsNumberBorder', 'eri', 'personalName', 'shirtNest1'] as const

export type ColorPlaceholderSelectorKind = typeof colorPlaceholderSelectorKinds[number]

export class ColorPlaceholderSelector {
    constructor(
        private readonly editor: Editor,
        readonly colorPlaceholders: ColorPlaceholder[],
        readonly enableColorPlaceholders: ColorPlaceholder[]
    ) { }
}

export const angleSwapDirections = ['left', 'right'] as const

export type AngleSwapDirection = typeof angleSwapDirections[number]

export interface DesignShareInfo {
    designId: string
    designUrl: string
    twitterUrl: string
    lineUrl: string
}
