import { createContext, FC, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { deserializeDesign, Design, InitialDesignServer } from "../../types/design";
import { Editor, MarkingImage, Snapshot } from "../../types/editor";
import { Server } from "../../types/server";

export const EditorProvider: FC<ProviderProps> = ({ children, server, initServer, designServer }) => {
    const dispatch = (editor: Editor): void => {
        setEditor(editor)
    }
    const [editor, setEditor] = useState<Editor>(() => new Editor(dispatch, {
        design: undefined,
        category: 'shirt',
        manType: "man01",
        savedManType: 'man01',
        angle: "front",
        zoom: 'none',
        selectedColorPlaceholder: undefined,
        selectedMarkingPlacement: undefined,
        selectedMarkingType: undefined,
        selectedMarkingAngle: undefined,
        selectedSubCategories: {
            shirt: "shirtDesign",
        },
    }))
    const setDesign = useCallback((design: Design) => {
        setEditor(editor => (new Editor(dispatch, { ...editor, design })))
    }, [])
    const uploadMarkingImage = useCallback(async (file: File): Promise<MarkingImage> => {
        return await server.uploadMarkingImage(file)
    }, [server])
    const getMarkingImage = useCallback(async (id: string): Promise<MarkingImage> => {
        return await server.getMarkingImage(id)
    }, [server])



    // DESIGN


    const loadDefault = useCallback(async () => {
        const defaultDesign = await initServer.getDefaultDesign()
        setDesign(defaultDesign)
    }, [initServer, setDesign])
    const addToFavorite = useCallback(async (snapshots: Snapshot[]) => {
        return await designServer.addToFavorites(editor.design!, snapshots)
    }, [designServer, editor.design])

    const saveDesign = useCallback(async () => {
        return await designServer.saveDesign(editor.design!)
    }, [designServer, editor.design])

    const loadDesignFromData = useCallback(async (data: string) => {
        const design = deserializeDesign(data)!
        setDesign(design)
    }, [setDesign])

    const loadDesignFromId = useCallback(async (id: string) => {
        const data = await server.getDesign(id)
        const design = deserializeDesign(data)!
        setDesign(design)
    }, [server, setDesign])

    useEffect(() => {
        loadDefault()
    }, [loadDefault])

    // DESIGN

    const value: EditorContext = {
        editor,
        uploadMarkingImage,
        getMarkingImage,
        markingImageServer: server,

        // DESIGN
        addToFavorite,
        designServer,
        loadDefault,
        loadDesignFromData,
        loadDesignFromId,
        loaded: editor.design != null,
        saveDesign,
        // DESIGN

    }
    return (
        <context.Provider value={value}>{children}</context.Provider>
    )
}

export const useEditor = (): EditorContext => useContext(context)!

const context = createContext<EditorContext | null>(null)

interface EditorContext {
    readonly editor: Editor,
    readonly uploadMarkingImage: (file: File) => Promise<MarkingImage>
    readonly getMarkingImage: (id: string) => Promise<MarkingImage>
    readonly markingImageServer: Server

    designServer: Server
    loaded: boolean
    loadDefault: () => Promise<void>
    addToFavorite: (snapshots: Snapshot[]) => Promise<string>
    saveDesign: () => Promise<string>
    loadDesignFromData: (data: string) => void
    loadDesignFromId: (id:string) => void
}

interface ProviderProps {
    server: Server
    initServer: InitialDesignServer
    designServer: Server
    loading: ReactElement
}
