import { Design, InitialDesignServer } from "../types/design";

export class BundleInitialDesignServer implements InitialDesignServer {
    async getDefaultDesign(): Promise<Design> {
        return new Design({
            assetsVersion: "0.0.1",
            patternAssetIds: {
                shirt: "shirt0001",
                eri: "eri0001",
                pants: "pants0001",
                socks: "socks0001",
            },
            nestedPatternAssetIds: {
                shirtNest1: 'shirtNest0001'
            },
            colors: {
                shirtMain: "color0001",
                shirtSub1: "color0001",
                eriMain: "color0002",
                eriSub1: "color0001",
                pantsMain: "color0002",
                pantsSub1: "color0001",
                pantsSub2: "color0014",
                pantsSub3: "color0016",
                pantsSub4: "color0015",
                innerShirtMain: "color0002",
                innerShirtPrint: "color0001",
                socksMain: "color0007",
                socksSub1: "color0002",
                socksSub2: "color0013",
                socksPrint: "color0002",
                shirtNumberFront: 'color0001',
                shirtNumberBack: 'color0001',
                shirtNumberBorder1: 'color0002',
                pantsNumber: 'color0002',
                pantsNumberBorder1: 'color0002',
                personalName: 'color0001',
                shirtNest1Main: 'color0009',
                shirtNest1Sub1: 'color0010',
            },
            texts: {
                number: "10",
                personalName: "PLAYER",
                teamName: "TEAMS",
                socksName: '',
                innerShirtName: '',
            },
            fontIds: {
                number: 'originalNumber01',
                innerShirtName: 'Dosis-ExtraBold',
                personalName: 'Dosis-ExtraBold',
                socksName: 'SublimaBold',
                teamName: 'Lobster',
            },
            markings: {},
            innerShirtType: 'none',
            innerShirtPrintPlacement: 'none',
            socksPrintPlacement: 'none',
            shirtFrontNumberPlacement: 'none',
            shirtBackNumberPlacement: 'none',
            pantsNumberPlacement: 'none',
            personalNamePlacement: 'none',
            shirtNumberBorderType: 'none',
            pantsNumberBorderType: 'none',
            shirtLogo: "leftBlack",
            pantsLogo: "leftFrontBlack",
        })
    }
}