import { ChangeEvent, ComponentType, Dispatch, FormEvent, ReactElement, SetStateAction, useEffect } from "react"
import { Order, ShirtSize, shirtSizes } from "../types/order"

const MAX_NUM = 100

export const OrderForm: ComponentType<FormProps> = ({ order, setOrder, sameAddress, setSameAddress, onSubmit, onCancel }) => {
    const handleSubmit = (evt: FormEvent) => {
        evt.preventDefault()
        onSubmit()
    }
    const renderNumOptions = (): ReactElement[] => {
        const a: ReactElement[] = []

        for (let i = 0; i <= MAX_NUM; i++) {
            a.push(<option key={`${i}`}>{i}</option>)
        }
        return a
    }
    const handleChangeSize = (size: ShirtSize) => (evt: ChangeEvent<HTMLSelectElement>) => {
        const s = evt.target.value
        const n = parseInt(s)
        setOrder(order => ({
            ...order!,
            sizeNums: {
                ...order!.sizeNums,
                [size]: n,
            },
        }))
    }
    const handleChangeValue = (key: keyof Order) => (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOrder(order => ({
            ...order!,
            [key]: evt.target.value,
        }))
    }
    const inputProps = (key: keyof Order) => {
        return ({
            value: order![key] as string,
            onChange: handleChangeValue(key),
        })
    }

    return (
        <div className="orderBase">
            <div className="orderBlock">
                <div className="backSimu" onClick={() => onCancel()}>シミュレーターへ戻る</div>
                <h2 className="orderH2">ご注文フォーム</h2>

                <form onSubmit={handleSubmit} >
                    <div className="h3Line">
                        <div className="contents980">
                            <h3 className="lineTitle">代理店情報</h3>
                        </div>
                    </div>
                    <div className="kaigoorder980">
                        <table className="kaigoOrder">
                            <tbody>
                                <tr>
                                    <th>代理店名</th>
                                    <td>
                                        <input  {...inputProps('dairitenName')} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th>ご担当者名</th>
                                    <td>
                                        <input  {...inputProps('dairitenTantouName')} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th>郵便番号</th>
                                    <td>
                                        <input  {...inputProps('dairitenZipcode')} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th>ご住所</th>
                                    <td>
                                        <input  {...inputProps('dairitenAddress')} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th>電話番号</th>
                                    <td>
                                        <input  {...inputProps('dairitenTel')} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th>メールアドレス</th>
                                    <td>
                                        <input  {...inputProps('dairitenEmail')} type="email" required />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="kaigoorder980">
                        <div className="otodokeCheck">
                            <input type="checkbox" id="otodokebetsu" name="otodokebetsu" checked={!sameAddress} onChange={evt => setSameAddress(!evt.target.checked)} />
                            <label htmlFor="otodokebetsu" className="checkbox01">お届け先が上記「代理店情報」と異なる場合</label>
                        </div>
                    </div>
                    {!sameAddress && (
                        <>
                            <div className="h3Line">
                                <div className="contents980">
                                    <h3 className="lineTitle">商品配送先情報</h3>
                                </div>
                            </div>
                            <div className="kaigoorder980">
                                <table className="kaigoOrder">
                                    <tbody>
                                        <tr>
                                            <th>送り先名</th>
                                            <td>
                                                <input  {...inputProps('destName')} required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>郵便番号</th>
                                            <td>
                                                <input  {...inputProps('destZipcode')} required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>ご住所</th>
                                            <td>
                                                <input  {...inputProps('destAddress')} required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>電話番号</th>
                                            <td>
                                                <input  {...inputProps('destTel')} required />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    <div className="h3Line">
                        <div className="contents980">
                            <h3 className="lineTitle">商品情報</h3>
                        </div>
                    </div>

                    <div className="kaigoorder980 overx">
                        <table className="kaigoOrder">
                            <tbody>
                                <tr>
                                    <td className="designImg" rowSpan={10}>
                                        <img src={order.snapshotFrontUrl} alt="" /><br />
                                        <img src={order.snapshotBackUrl} alt="" />
                                    </td>
                                    <th>案件名</th>
                                    <td><input {...inputProps('ankenName')} required /></td>
                                </tr>
                                <tr>
                                    <th>デザイン</th>
                                    <td>{order.shirtName}</td>
                                </tr>
                                <tr>
                                    <th>ベースカラー</th>
                                    <td>{order.baseColorName}/{order.baseColorRgb}</td>
                                </tr>
                                <tr>
                                    <th>ボタン</th>
                                    <td>{order.buttonColorName}/{order.buttonColorRgb}</td>
                                </tr>

                                <tr>
                                    <th>柄</th>
                                    <td>{order.garaName}</td>
                                </tr>
                                <tr>
                                    <th>柄ベースカラー</th>
                                    <td>{order.garaMainColorName}/{order.garaMainColorRgb}</td>
                                </tr>
                                <tr>
                                    <th>柄サブカラー</th>
                                    <td>{order.garaSubColorName}/{order.garaSubColorRgb}</td>
                                </tr>
                                <tr>
                                    <th>備考</th>
                                    <td>
                                        <textarea {...inputProps('remark')} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="h3Line">
                        <div className="contents980">
                            <h3 className="lineTitle">注文枚数</h3>
                        </div>
                    </div>

                    <div className="kaigoorder980 overx">
                        <table className="kaigoOrder size">
                            <thead>
                                <tr>
                                    {shirtSizes.map(size => (
                                        <th key={size}>{size}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {shirtSizes.map(size => (
                                        <td key={size}>
                                            <select value={order.sizeNums[size]} onChange={handleChangeSize(size)}>
                                                {renderNumOptions()}
                                            </select>　枚
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button type="submit">発注する</button>
                </form>
            </div>
        </div>
    )
}

export default OrderForm

interface FormProps {
    order: Order
    setOrder: Dispatch<SetStateAction<Order | null>>
    onSubmit: () => any,
    onCancel: () => any,
    sameAddress: boolean
    setSameAddress: (b: boolean) => any
}
