import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useEditor } from "./providers/editor"

const LoadDesignFromId = () => {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const { loadDesignFromId } = useEditor()
    const id = params.get('designId')
    const loadAsync = useCallback(async () => {
        if (id != null) {
            await loadDesignFromId(id)
        }
        navigate('/')
    }, [id, loadDesignFromId, navigate])
    useEffect(() => {
        loadAsync()
    }, [loadAsync])
    return <></>
}

export default LoadDesignFromId
