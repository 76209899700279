import { ComponentType, FC } from "react"

export const ThumbSelector: FC = ({ children }) => {
    return (
        <div className="optionPalette">
            <div className="paletteOptionList clr">
                {children}
            </div>
        </div>
    )
}

export const ThumbSelectorItem: ComponentType<ItemProps> = ({ thumb, selected, onClick }) => {
    const className = selected ? 'optionSquare select' : 'optionSquare'
    return (
        <div onClick={onClick} className={className}>
            <span>
                <img
                    src="http://images.sfidasports.com/user_data/201905/square300.png"
                    style={{
                        background: `url(${thumb}) center center / contain no-repeat #ffffff`,
                        objectFit: 'contain',
                    }}
                    alt=""
                />
            </span>
        </div>
    )
}


interface ItemProps {
    thumb: string
    selected: boolean
    onClick: () => any
}
