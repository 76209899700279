import { createContext, FC, useCallback, useContext, useState } from "react";
import { Assets, AssetsServer, Font } from "../../types/asset";

interface AssetsContext {
    readonly assets: Assets
    readonly loaded: boolean
    readonly loadAssets: (assetsVersion: string) => Promise<void>
}

const context = createContext<AssetsContext | null>(null)

export const AssetsProvider: FC<{ server: AssetsServer }> = ({ children, server }) => {
    const [loaded, setLoaded] = useState<boolean>(false)
    const [assets, setAssets] = useState<Assets>(new Assets({
        manImage: {
            front: {},
            back: {},
            hira: {},
        },
        refImage: {
            front: {},
            back: {},
            hira: {},
        },
        shadowImage: {
            front: {},
            back: {},
            hira: {},
        },
        innerShirtImage: {
            front: {},
            back: {},
            hira: {},
        },
        shirtLogoImages: {
            man01: {
                centerBlack: '',
                centerWhite: '',
                leftBlack: '',
                leftWhite: '',
                rightBlack: '',
                rightWhite: '',
            },
            man02: {
                centerBlack: '',
                centerWhite: '',
                leftBlack: '',
                leftWhite: '',
                rightBlack: '',
                rightWhite: '',
            },
        },
        pantsLogoImages: {
            man01: {
                'leftBackBlack': '',
                'leftBackWhite': '',
                'leftFrontBlack': '',
                'leftFrontWhite': '',
                'rightBackBlack': '',
                'rightBackWhite': '',
                'rightFrontBlack': '',
                'rightFrontWhite': '',
            },
            man02: {
                'leftBackBlack': '',
                'leftBackWhite': '',
                'leftFrontBlack': '',
                'leftFrontWhite': '',
                'rightBackBlack': '',
                'rightBackWhite': '',
                'rightFrontBlack': '',
                'rightFrontWhite': '',
            },
        },
        patternsAssets: {
            shirt: [],
            eri: [],
            pants: [],
            socks: [],
            shirtNest: [],
        },
        colors: [],
        fonts: [],
    }))
    const loadFont = useCallback(async (font: Font): Promise<void> => {
        if (font.url == null) {
            return
        }
        const f = await new FontFace(font.family, `url(${font.url})`, { weight: font.weight, style: font.style }).load()
        document.fonts.add(f)
    }, [])
    const loadAssets = useCallback(async (assetsVersion: string) => {
        const assets = await server.getAssets()
        for (let i = 0; i < assets.fonts.length; i++) {
            await loadFont(assets.fonts[i])
        }
        setAssets(assets)
        setLoaded(true)
    }, [loadFont, server])
    const value = { assets, loaded, loadAssets }
    return (
        <context.Provider value={value}>{children}</context.Provider>
    )
}

export function useAssets(): AssetsContext {
    return useContext(context)!
}
