import { MouseEvent, useState } from "react"
import tinycolor from "tinycolor2"
import { useAssets } from "./providers/assets"
import { Font } from "../types/asset"
import { useEditor } from "./providers/editor"
import { markingPlacementLabels } from "./strings"


const MarkingTextInput = () => {
    const { editor } = useEditor()
    const { assets } = useAssets()
    const [error, setError] = useState('')
    const initialText = (): string => {
        const marking = editor.design!.markings[editor.selectedMarkingPlacement!]
        if (marking === undefined) {
            return ''
        } else if (marking.type !== 'text') {
            return ''
        }
        return marking.text!.text
    }
    const initialFontId = (): string => {
        const marking = editor.design!.markings[editor.selectedMarkingPlacement!]
        if (marking === undefined) {
            return assets.fonts[0].id
        } else if (marking.type !== 'text') {
            return assets.fonts[0].id
        }
        return marking.text!.fontId
    }
    const initialColorId = (): string => {
        const marking = editor.design!.markings[editor.selectedMarkingPlacement!]
        if (marking === undefined) {
            return assets.colors[0].id
        } else if (marking.type !== 'text') {
            return assets.colors[0].id
        }
        return marking.text!.colorId
    }
    const [text, setText] = useState(initialText())
    const [fontId, setFontId] = useState(initialFontId())
    const [colorId, setColorId] = useState(initialColorId())

    const color = assets.getColor(colorId)

    const fontItemClassName = (item: Font): string => {
        if (fontId === item.id) {
            return 'selected'
        } else {
            return ''
        }
    }
    const handleClickSave = () => {
        setError('')
        const [valid, { errors }] = editor.design!.isValidMarkingText(text)
        if (!valid) {
            setError(errors[0])
            return
        }
        editor.setMarkingText(editor.selectedMarkingPlacement!, {
            text,
            fontId,
            colorId,
        })
    }
    const handleClickBack = () => {
        editor.clearMarkingTypeSelect()
    }
    const handleClickOverlay = (evt:MouseEvent<HTMLDivElement>) => {
        evt.preventDefault()
        handleClickBack()
    }
    const handleClickOverlayChildren = (evt:MouseEvent<HTMLDivElement>) => {
        evt.preventDefault()
        evt.stopPropagation()
    }
    return (
        <div className="markingTypeSlectBlock markingTextInput" onClick={handleClickOverlay}>
            <div className="markingTextInputBlock" onClick={handleClickOverlayChildren}>

                <div className="subBlock">
                    <h4>「{markingPlacementLabels[editor.selectedMarkingPlacement!]}」へのマーキング内容の入力</h4>
                    <div className="inputBlock">
                        <input type="text" value={text} onChange={evt => setText(evt.target.value)} />
                    </div>
                </div>
                {error !== "" && (
                    <div className="text-input--error">{error}</div>
                )}
                <div className="subBlock">
                    <div>
                        <div className="font-selector-container">
                            {assets.fonts.filter(v => v.tags.includes('en')).map(font => (
                                <div
                                    key={font.id}
                                    className={fontItemClassName(font)}
                                    onClick={() => setFontId(font.id)}
                                    style={{
                                        fontFamily: font.family,
                                    }}
                                >
                                    <div className="check"></div>
                                    <div className="fontDisplay">ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz</div>


                                </div>
                            ))}
                            {assets.fonts.filter(v => v.tags.includes('jpfont')).map(font => (
                                <div
                                    key={font.id}
                                    className={fontItemClassName(font)}
                                    onClick={() => setFontId(font.id)}
                                    style={{
                                        fontFamily: font.family,
                                    }}
                                >
                                    <div className="check"></div>
                                    <div className="fontDisplay">{font.getSample('jp')}</div>


                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="subBlock">
                    <h4>テキストカラー選択</h4>

                    <div className="colorPalette">
                        <div className="paletteColorList clr">
                            {assets.colors.map(item => (
                                <div
                                    key={item.id}
                                    className="colorRound"
                                    onClick={() => setColorId(item.id)}
                                >
                                    <span className="">
                                        <span
                                            className=""
                                            style={{
                                                backgroundColor: tinycolor(item!.rgb).toRgbString(),
                                            }}
                                            onClick={() => setColorId(item.id)}
                                        >
                                            <span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <button className="textReflection" type="button" onClick={handleClickSave}>反映</button>
                    <button className="markingRtn" type="button" onClick={handleClickBack}>戻る</button>
                </div>
            </div>
        </div>
    )
}

export default MarkingTextInput