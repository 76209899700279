export class Design {
    readonly assetsVersion: string
    readonly colors: { [key in ColorPlaceholder]: string }
    readonly patternAssetIds: {
        [kind in PatternsKind]: string
    }
    readonly nestedPatternAssetIds: {
        [kind in NestedPatternPlacement]: string
    }
    readonly texts: {
        [kind in TextKind]: string
    }
    readonly fontIds: {
        [kind in TextKind]: string
    }
    readonly markings: {
        [placement in MarkingPlacement]?: Marking
    }
    readonly innerShirtType: InnerShirtType
    readonly innerShirtPrintPlacement: InnerShirtPrintPlacement
    readonly socksPrintPlacement: SocksPrintPlacement
    readonly shirtFrontNumberPlacement: ShirtFrontNumberPlacement
    readonly shirtBackNumberPlacement: ShirtBackNumberPlacement
    readonly pantsNumberPlacement: PantsNumberPlacement
    readonly personalNamePlacement: PersonalNamePlacement
    readonly shirtNumberBorderType: ShirtNumberBorderType
    readonly pantsNumberBorderType: PantsNumberBorderType
    readonly shirtLogo: ShirtLogo
    readonly pantsLogo: PantsLogo
    constructor(args: {
        assetsVersion: string
        patternAssetIds: { [kind in PatternsKind]: string }
        nestedPatternAssetIds: {[kind in NestedPatternPlacement]: string}
        colors: { [key in ColorPlaceholder]: string }
        texts: { [key in TextKind]: string }
        fontIds: { [key in TextKind]: string }
        markings: { [pos in MarkingPlacement]?: Marking }
        innerShirtType: InnerShirtType,
        innerShirtPrintPlacement: InnerShirtPrintPlacement
        socksPrintPlacement: SocksPrintPlacement
        shirtFrontNumberPlacement: ShirtFrontNumberPlacement
        shirtBackNumberPlacement: ShirtBackNumberPlacement
        pantsNumberPlacement: PantsNumberPlacement
        personalNamePlacement: PersonalNamePlacement
        shirtNumberBorderType: ShirtNumberBorderType
        pantsNumberBorderType: PantsNumberBorderType
        shirtLogo: ShirtLogo
        pantsLogo: PantsLogo
    }) {
        this.assetsVersion = args.assetsVersion
        this.colors = args.colors
        this.patternAssetIds = args.patternAssetIds
        this.nestedPatternAssetIds = args.nestedPatternAssetIds
        this.texts = args.texts
        this.fontIds = args.fontIds
        this.markings = args.markings
        this.innerShirtType = args.innerShirtType
        this.innerShirtPrintPlacement = args.innerShirtPrintPlacement
        this.socksPrintPlacement = args.socksPrintPlacement
        this.shirtFrontNumberPlacement = args.shirtFrontNumberPlacement
        this.shirtBackNumberPlacement = args.shirtBackNumberPlacement
        this.pantsNumberPlacement = args.pantsNumberPlacement
        this.personalNamePlacement = args.personalNamePlacement
        this.shirtNumberBorderType = args.shirtNumberBorderType
        this.pantsNumberBorderType = args.pantsNumberBorderType
        this.shirtLogo = args.shirtLogo
        this.pantsLogo = args.pantsLogo
    }

    hasInnerShirt(): boolean {
        return this.innerShirtType !== 'none'
    }

    hasInnerShirtPrint(): boolean {
        if (!this.hasInnerShirt()) {
            return false
        }
        return this.innerShirtPrintPlacement !== 'none'
    }

    hasSocksPrint(): boolean {
        return this.socksPrintPlacement !== 'none'
    }

    hasShirtFrontNumber(): boolean {
        return this.shirtFrontNumberPlacement !== 'none'
    }

    hasShirtBackNumber(): boolean {
        return this.shirtBackNumberPlacement !== 'none'
    }

    hasShirtNumber(): boolean {
        return this.hasShirtFrontNumber() || this.hasShirtBackNumber()
    }

    hasShirtNumberBorder(): boolean {
        return this.getShirtNumberBorderCount() > 0
    }

    getShirtNumberBorderCount(): number {
        return this.getShirtNumberBorderColorPlaceholders().length
    }

    getShirtNumberBorderColorPlaceholders(): ColorPlaceholder[] {
        switch (this.shirtNumberBorderType) {
            case 'border1':
                return ['shirtNumberBorder1']
            case 'none':
                return []
        }
    }

    hasPantsNumber(): boolean {
        return this.pantsNumberPlacement !== 'none'
    }

    hasPantsNumberBorder(): boolean {
        return this.getPantsNumberBorderCount() > 0
    }

    getPantsNumberBorderCount(): number {
        return this.getPantsNumberBorderColorPlaceholders().length
    }

    getPantsNumberBorderColorPlaceholders(): ColorPlaceholder[] {
        switch (this.pantsNumberBorderType) {
            case 'border1':
                return ['pantsNumberBorder1']
            case 'none':
                return []
        }
    }

    hasNumber(): boolean {
        return this.hasShirtNumber() || this.hasPantsNumber()
    }

    hasPersonalName(): boolean {
        return this.personalNamePlacement !== 'none'
    }

    getPatternAssetId(kind: PatternsKind) {
        return this.patternAssetIds[kind]
    }

    changePattern(kind: PatternsKind, assetId: string): Design {
        return new Design({
            ...this,
            patternAssetIds: {
                ...this.patternAssetIds,
                [kind]: assetId,
            },
        })
    }

    changeNestedPattern(placement:NestedPatternPlacement, assetId: string): Design {
        return new Design({
            ...this,
            nestedPatternAssetIds: {
                ...this.nestedPatternAssetIds,
                [placement]: assetId,
            },
        })
    }

    changeColor(colorPlaceholder: ColorPlaceholder, colorId: string): Design {
        const colors = {
            ...this.colors,
            [colorPlaceholder]: colorId,
        }
        if (colorPlaceholder === 'shirtNumberBack') {
            colors['shirtNumberFront'] = colorId
        } else if (colorPlaceholder === 'shirtNumberFront') {
            colors['shirtNumberBack'] = colorId
        }
        return new Design({
            ...this,
            colors,
        })
    }

    changeInnerShirtType(innerShirtType: InnerShirtType): Design {
        return new Design({ ...this, innerShirtType })
    }

    changeInnerShirtPrintPlacement(innerShirtPrintPlacement: InnerShirtPrintPlacement): Design {
        return new Design({ ...this, innerShirtPrintPlacement })
    }

    changeSocksPrintPlacement(socksPrintPlacement: SocksPrintPlacement): Design {
        return new Design({ ...this, socksPrintPlacement })
    }

    changeShirtFrontNumberPlacement(shirtFrontNumberPlacement: ShirtFrontNumberPlacement): Design {
        return new Design({ ...this, shirtFrontNumberPlacement })
    }

    changeShirtBackNumberPlacement(shirtBackNumberPlacement: ShirtBackNumberPlacement): Design {
        return new Design({ ...this, shirtBackNumberPlacement })
    }

    changePantsNumberPlacement(pantsNumberPlacement: PantsNumberPlacement): Design {
        return new Design({ ...this, pantsNumberPlacement })
    }

    changePersonalNamePlacement(personalNamePlacement: PersonalNamePlacement): Design {
        return new Design({ ...this, personalNamePlacement })
    }

    changeShirtNumberBorderType(shirtNumberBorderType: ShirtNumberBorderType): Design {
        return new Design({ ...this, shirtNumberBorderType })
    }

    changePantsNumberBorderType(pantsNumberBorderType: PantsNumberBorderType): Design {
        return new Design({ ...this, pantsNumberBorderType })
    }

    changeShirtLogo(shirtLogo: ShirtLogo): Design {
        return new Design({ ...this, shirtLogo })
    }

    changePantsLogo(pantsLogo: PantsLogo): Design {
        return new Design({ ...this, pantsLogo })
    }

    changeText(textKind: TextKind, text: string): Design {
        return new Design({
            ...this,
            texts: {
                ...this.texts,
                [textKind]: text,
            }
        })
    }

    changeFontId(textKind: TextKind, fontId: string): Design {
        return new Design({
            ...this, fontIds: {
                ...this.fontIds,
                [textKind]: fontId,
            }
        })
    }

    setMarkingText(placement: MarkingPlacement, markingText: MarkingText): Design {
        const marking: Marking = {
            placement,
            type: 'text',
            text: markingText,
        }
        return new Design({
            ...this,
            markings: {
                ...this.markings,
                [placement!]: marking,
            }
        })
    }

    setMarkingImageId(placement: MarkingPlacement, markingImageId: string): Design {
        const marking: Marking = {
            placement,
            type: 'image',
            imageId: markingImageId,
        }
        return new Design({
            ...this,
            markings: {
                ...this.markings,
                [placement!]: marking,
            },
        })
    }

    deleteMarking(placement: MarkingPlacement): Design {
        return new Design({
            ...this,
            markings: {
                ...this.markings,
                [placement!]: null,
            },
        })
    }

    isMarkingSet(placement: MarkingPlacement): boolean {
        return this.markings[placement] != null
    }

    isFrontVisiblePantsLogo(): boolean {
        switch (this.pantsLogo) {
            case 'leftBackBlack':
            case 'leftBackWhite':
            case 'rightBackBlack':
            case 'rightBackWhite':
                return false
            case 'rightFrontBlack':
            case 'rightFrontWhite':
            case 'leftFrontBlack':
            case 'leftFrontWhite':
                return true
        }
    }

    isBackVisiblePantsLogo(): boolean {
        return !this.isFrontVisiblePantsLogo()
    }

    isSocksPrintVisible(): boolean {
        if (this.socksPrintPlacement === 'none') {
            return false
        }
        return true
    }

    isFrontVisibleSocksPrint(): boolean {
        switch (this.socksPrintPlacement) {
            case 'none':
                return false
            case 'socksPrint1':
                return true
            case 'socksPrint2':
                return false
        }
    }

    isBackVisibleSocksPrint(): boolean {
        return this.isSocksPrintVisible() && !this.isFrontVisibleSocksPrint()
    }

    getAllTextCharacters(): string {
        const ks: TextKind[] = Object.keys(this.texts) as TextKind[]
        const o: { [key in string]: boolean } = {}
        for (let i = 0; i < ks.length; i++) {
            const k = ks[i]
            const s = this.texts[k]
            for (let j = 0; j < s.length; j++) {
                o[s[j]] = true
            }
        }
        for (let i = 0; i < markingPlacements.length; i++) {
            const pl = markingPlacements[i]
            const marking = this.markings[pl]
            if (marking == null) {
                continue
            }
            if (marking.type !== 'text') {
                continue
            }
            if (marking.text == null) {
                continue
            }
            for (let j = 0; j < marking.text.text.length; j++) {
                o[marking.text.text[j]] = true
            }
        }
        return Object.keys(o).sort().join('')
    }

    isValidSocksPrintText(text: string): [boolean, { errors: string[] }] {
        const re = /^[a-zA-Z0-9]{1,10}$/
        const ret = re.exec(text)
        if (ret == null || ret.length === 0) {
            return [false, {
                errors: ["半角英数10文字以内で入力してください"],
            }]
        }
        return [true, { errors: [] }]
    }

    isValidInnerShirtPrintText(text: string): [boolean, { errors: string[] }] {
        const re = /^[a-zA-Z0-9]{1,13}$/
        const ret = re.exec(text)
        if (ret == null || ret.length === 0) {
            return [false, {
                errors: ["半角英数13文字以内で入力してください"],
            }]
        }
        return [true, { errors: [] }]
    }

    isValidNumberText(text: string): [boolean, { errors: string[] }] {
        const re = /^[0-9]{1,2}$/
        const ret = re.exec(text)
        if (ret == null || ret.length === 0) {
            return [false, {
                errors: ["数字2文字以内で入力してください"],
            }]
        }
        return [true, { errors: [] }]
    }

    isValidPersonalNameText(text: string): [boolean, { errors: string[] }] {
        const re = /^[a-zA-Z0-9]{1,13}$/
        const ret = re.exec(text)
        if (ret == null || ret.length === 0) {
            return [false, {
                errors: ["半角英数13文字以内で入力してください"],
            }]
        }
        return [true, { errors: [] }]
    }

    isValidMarkingText(text: string): [boolean, { errors: string[] }] {
        const re = /^[a-zA-Z0-9]{1,25}$/
        const ret = re.exec(text)
        if (ret == null || ret.length === 0) {
            return [false, {
                errors: ["半角英数25文字以内で入力してください"],
            }]
        }
        return [true, { errors: [] }]
    }
}

export const textKinds = [
    "teamName",
    "personalName",
    "number",
    "socksName",
    "innerShirtName",
] as const

export type TextKind = typeof textKinds[number]

export const markingTypes = [
    "text",
    "image",
] as const

export type MarkingType = typeof markingTypes[number]

export const shirtFrontMarkingPlacements = [
    "shirtFront01",
    "shirtFront02",
    "shirtFront03",
    "shirtFront04",
    "shirtFront05",
    "shirtFront06",
    "shirtFront07",
    "shirtFront08",
    "shirtFront09",
    "shirtFront10",
    "shirtFront11",
    "shirtFront12",
    "shirtFront13",
    "shirtFront14",
    "shirtFront15",
    "shirtFront16",
] as const

export type ShirtFrontMarkingPlacement = typeof shirtFrontMarkingPlacements[number]

export const shirtBackMarkingPlacements = [
    "shirtBack01",
    "shirtBack02",
    "shirtBack03",
    "shirtBack04",
    "shirtBack05",
    "shirtBack06",
    "shirtBack07",
    "shirtBack08",
    "shirtBack09",
    "shirtBack09_2",
    "shirtBack10",
    "shirtBack11",
    "shirtBack12",
    "shirtBack13",
] as const

export type ShirtBackmarkingPlacement = typeof shirtBackMarkingPlacements[number]

export const pantsFrontMarkingPlacements = [
    "pantsFront01",
    "pantsFront02",
    "pantsFront03",
    "pantsFront04",
] as const

export type PantsFrontMarkingPlacement = typeof pantsFrontMarkingPlacements[number]

export const pantsBackMarkingPlacements = [
    "pantsBack01",
    "pantsBack02",
] as const

export type PantsBackMarkingPlacement = typeof pantsBackMarkingPlacements[number]

export type MarkingPlacement = ShirtFrontMarkingPlacement | ShirtBackmarkingPlacement | PantsFrontMarkingPlacement | PantsBackMarkingPlacement

export const markingPlacements = [...shirtFrontMarkingPlacements, ...shirtBackMarkingPlacements, ...pantsFrontMarkingPlacements, ...pantsBackMarkingPlacements]

export interface MarkingText {
    text: string
    fontId: string
    colorId: string
}

export interface Marking {
    placement: MarkingPlacement,
    type: MarkingType,
    text?: MarkingText,
    imageId?: string,
}

export const shirtLogos = ["leftBlack", "centerBlack", "rightBlack", "leftWhite", "centerWhite", "rightWhite"] as const

export type ShirtLogo = typeof shirtLogos[number]

export const pantsLogos = ["leftFrontBlack", "rightFrontBlack", "leftFrontWhite", "rightFrontWhite", "leftBackBlack", "rightBackBlack", "leftBackWhite", "rightBackWhite"] as const

export type PantsLogo = typeof pantsLogos[number]

export const innerShirtTypes = ["inner1", "none"] as const

export type InnerShirtType = typeof innerShirtTypes[number]

export const innerShirtPrintPlacements = ["mune", "koshi", "ude", 'none'] as const

export type InnerShirtPrintPlacement = typeof innerShirtPrintPlacements[number]

export const socksPrintPlacements = ['socksPrint1', 'socksPrint2', 'none'] as const

export type SocksPrintPlacement = typeof socksPrintPlacements[number]

export const shirtFrontNumberPlacements = ['right', 'center', 'left', 'hara', 'none'] as const

export type ShirtFrontNumberPlacement = typeof shirtFrontNumberPlacements[number]

export const shirtBackNumberPlacements = ['back', 'none'] as const

export type ShirtBackNumberPlacement = typeof shirtBackNumberPlacements[number]

export const pantsNumberPlacements = ['right', 'left', 'none'] as const

export type PantsNumberPlacement = typeof pantsNumberPlacements[number]

export const shirtNumberBorderTypes = ['none', 'border1'] as const

export type ShirtNumberBorderType = typeof shirtNumberBorderTypes[number]

export const pantsNumberBorderTypes = ['none', 'border1'] as const

export type PantsNumberBorderType = typeof pantsNumberBorderTypes[number]

export const personalNamePlacements = ['top','topArch' ,'bottom', 'none'] as const

export type PersonalNamePlacement = typeof personalNamePlacements[number]

export const patternsAssetKinds = ["shirt", "eri", "pants", "socks", 'shirtNest'] as const

export type PatternsAssetKind = typeof patternsAssetKinds[number]

export const patternsKinds = ["shirt", "eri", "pants", "socks"] as const

export type PatternsKind = typeof patternsKinds[number]

export const colorPlaceholders = [
    "shirtMain",
    "shirtSub1",
    "eriMain",
    "eriSub1",
    "pantsMain",
    "pantsSub1",
    "pantsSub2",
    "pantsSub3",
    "pantsSub4",
    "innerShirtMain",
    "innerShirtPrint",
    "socksMain",
    "socksSub1",
    "socksSub2",
    "socksPrint",
    'shirtNumberFront',
    'shirtNumberBack',
    'shirtNumberBorder1',
    'pantsNumber',
    'pantsNumberBorder1',
    'personalName',
    'shirtNest1Main',
    'shirtNest1Sub1',
] as const

export type ColorPlaceholder = typeof colorPlaceholders[number]

export const nestedPatternPlacements = ['shirtNest1'] as const

export type NestedPatternPlacement = typeof nestedPatternPlacements[number]

export interface InitialDesignServer {
    getDefaultDesign(): Promise<Design>
}

export const serializeDesign = (design: Design): string => {
    const markingData = JSON.stringify(design.markings)
    const obj: any = {
        ...design,
        markingData,
    }
    return JSON.stringify(obj)
}

export const deserializeDesign = (data: string): (Design | undefined) => {
    const obj = JSON.parse(data)
    try {
        const desel = <T extends string, U>(obj: any, keys: readonly T[], defaultValues: { [key in T]: U }): { [key in T]: U } => {
            return keys.reduce((p, c) => {
                return {
                    ...p,
                    [c]: obj[c] as string,
                }
            }, defaultValues)
        }

        const colors = desel(obj.colors, colorPlaceholders, {
            shirtMain: "",
            shirtSub1: "",
            eriMain: "",
            eriSub1: "",
            pantsMain: "",
            pantsSub1: "",
            pantsSub2: "",
            pantsSub3: "",
            pantsSub4: "",
            innerShirtMain: "",
            innerShirtPrint: "",
            socksMain: "",
            socksSub1: "",
            socksSub2: "",
            socksPrint: "",
            shirtNumberFront: '',
            shirtNumberBack: '',
            shirtNumberBorder1: '',
            pantsNumber: '',
            pantsNumberBorder1: '',
            personalName: '',
            shirtNest1Main: '',
            shirtNest1Sub1: '',
        })

        const texts = desel(obj.texts, textKinds, {
            innerShirtName: '',
            number: '',
            personalName: '',
            socksName: '',
            teamName: '',
        })

        const fontIds = desel(obj.fontIds, textKinds, {
            innerShirtName: '',
            number: '',
            personalName: '',
            socksName: '',
            teamName: '',
        })

        const patternAssetIds = desel(obj.patternAssetIds, patternsKinds, {
            eri: '',
            pants: '',
            shirt: '',
            socks: '',
        })

        const nestedPatternAssetIds = desel(obj.nestedPatternAssetIds, nestedPatternPlacements, {
            shirtNest1: '',
        })


        // TODO: marking

        const desel2 = <T>(v: any, keys: readonly T[], defaultValue: T): T => {
            if (!keys.includes(v)) {
                return defaultValue
            }
            return v as T
        }
        return new Design({
            assetsVersion: '1',
            colors,
            texts,
            fontIds,
            patternAssetIds,
            nestedPatternAssetIds,
            innerShirtType: desel2(obj.innerShirtType, innerShirtTypes, 'none'),
            innerShirtPrintPlacement: desel2(obj.innerShirtPrintPlacement, innerShirtPrintPlacements, 'none'),
            pantsLogo: desel2(obj.pantsLogo, pantsLogos, 'leftFrontBlack'),
            pantsNumberBorderType: desel2(obj.pantsNumberBorderType, pantsNumberBorderTypes, 'none'),
            pantsNumberPlacement: desel2(obj.pantsNumberPlacement, pantsNumberPlacements, 'none'),
            personalNamePlacement: desel2(obj.personalNamePlacement, personalNamePlacements, 'none'),
            shirtBackNumberPlacement: desel2(obj.shirtBackNumberPlacement, shirtBackNumberPlacements, 'none'),
            shirtFrontNumberPlacement: desel2(obj.shirtFrontNumberPlacement, shirtFrontNumberPlacements, 'none'),
            shirtLogo: desel2(obj.shirtLogo, shirtLogos, 'leftBlack'),
            shirtNumberBorderType: desel2(obj.shirtNumberBorderType, shirtNumberBorderTypes, 'none'),
            socksPrintPlacement: desel2(obj.socksPrintPlacement, socksPrintPlacements, 'none'),
            markings: JSON.parse(obj.markingData) as Design['markings'],
        })
    } catch (error) {
        return undefined
    }
}
