import { Server } from "../../types/server"
import ImageLayer from "./ImageLayer"
import { Layer } from "./Layer"
import { ImageLayout, Rect } from "./types"

class MarkingImageLayer implements Layer {
    private readonly server: Server
    private image: string | null = null
    private imageLayer: ImageLayer|null = null

    constructor(
        readonly width: number,
        readonly height: number,
        readonly rect: Rect,
        readonly layout: ImageLayout,
        readonly imageId: string,
        server: Server
    ) {
        this.server = server
    }

    async draw(dst: HTMLCanvasElement): Promise<void> {
        if (this.imageLayer == null) {
            return
        }
        this.imageLayer.draw(dst)
    }

    load(): Promise<void>[] {
        return [
            this.loadMarkingImage(),
        ]
    }

    private async loadMarkingImage(): Promise<void> {
        const markingImage = await this.server.getMarkingImage(this.imageId)
        this.image = markingImage.url
        this.imageLayer = new ImageLayer(this.width, this.height, this.rect, this.layout, this.image)
        await this.imageLayer.load()
    }
}

export default MarkingImageLayer