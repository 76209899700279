import { ComponentType } from "react";
import { useEditor } from "./providers/editor";
import Shirt from "./Shirt";

const Control: ComponentType = () => {
    const { editor } = useEditor()

    switch (editor.category) {
        case 'shirt':
            return <Shirt />
        default:
            return <></>
    }
}

export default Control
