import { ComponentType } from "react"
import { useAssets } from "./providers/assets"
import { ColorPlaceholder, NestedPatternPlacement, PatternsAssetKind, PatternsKind } from "../types/design"
import { useEditor } from "./providers/editor"
import PatternSelector from "./PatternSelector"
import ColorPalette from "./ColorPalette"
import ColorPlaceholders from "./ColorPlaceholders"
import { ControlBlock } from "./style/editorControl"
import { Category, ColorPlaceholderSelectorKind, SubCategory } from "../types/editor"

const NestedPatternAndColor: ComponentType<Props> = ({ title, titleNumber, kind, category, subCategory, colorPlaceholderSelectorKind, placement }) => {
    const { assets } = useAssets()
    const { editor } = useEditor()
    const patternAssetId = editor.design!.nestedPatternAssetIds[placement]
    const patternAsset = assets.getPatternAsset(kind, patternAssetId)!
    const colorPlaceholders = editor.getColorPlaceholders(colorPlaceholderSelectorKind)
    const enableColorPlaceholders = colorPlaceholders.slice(0, patternAsset.patterns.length)
    const patternColorSelected = editor.selectedColorPlaceholder == null ? false : colorPlaceholders.includes(editor.selectedColorPlaceholder)

    const handleSelectPattern = (assetId: string) => {
        editor.changeNestedPattern(placement, assetId)
    }

    const isSelectPattern = (assetId: string) => {
        return assetId === editor.design!.nestedPatternAssetIds[placement]
    }

    return (
        <ControlBlock
            title={title}
            titleNumber={titleNumber}
            disabled={editor.isSubcategoryDisabled(subCategory)}
            selected={editor.selectedSubCategories[category] === subCategory}
        >
            <div className="seletNameColor">
                <div className="nameBox">{patternAsset.label}</div>
                <ColorPlaceholders
                    colorPlaceholders={colorPlaceholders}
                    enableColorPlaceholders={enableColorPlaceholders}
                />
            </div>

            <ColorPalette
                visible={editor.isColorPaletteVisible(colorPlaceholderSelectorKind)}
                enable={patternColorSelected}
                colorPlaceholder={editor.selectedColorPlaceholder!}
            />
            <PatternSelector kind={kind} onSelect={handleSelectPattern} isSelect={isSelectPattern} />
        </ControlBlock>
    )
}

export default NestedPatternAndColor

interface Props {
    title: string
    titleNumber: string
    placement: NestedPatternPlacement
    kind: PatternsAssetKind
    category: Category
    subCategory: SubCategory
    colorPlaceholderSelectorKind: ColorPlaceholderSelectorKind
}
