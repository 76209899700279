import { ColorPlaceholder, NestedPatternPlacement, PantsLogo, PatternsAssetKind, PatternsKind, ShirtLogo } from "./design"
import { Angle, ManType } from "./editor"

export class Assets {
    static readonly DEFAULT_FONT_SAMPLE = '1234567890'
    readonly manImage: AssetImage
    readonly refImage: AssetImage
    readonly shadowImage: AssetImage
    readonly innerShirtImage: AssetImage
    readonly shirtLogoImages: {
        [key in ManType]: {
            [key in ShirtLogo]: string
        }
    }
    readonly pantsLogoImages: {
        [key in ManType]: {
            [key in PantsLogo]: string
        }
    }
    readonly patternsAssets: {
        [key in PatternsAssetKind]: PatternsAsset[]
    }
    readonly colors: ColorAsset[]
    readonly fonts: Font[]

    constructor({ manImage, refImage, shadowImage, innerShirtImage, shirtLogoImages, pantsLogoImages, patternsAssets, colors, fonts }: ConstructorArg) {
        this.manImage = manImage
        this.refImage = refImage
        this.shadowImage = shadowImage
        this.innerShirtImage = innerShirtImage
        this.shirtLogoImages = shirtLogoImages
        this.pantsLogoImages = pantsLogoImages
        this.patternsAssets = patternsAssets
        this.colors = colors
        this.fonts = fonts
    }

    getPatternAssetList(kind: PatternsAssetKind): (PatternsAsset[] | undefined) {
        return this.patternsAssets[kind]
    }

    getPatternAsset(kind: PatternsAssetKind, assetId: string): (PatternsAsset | undefined) {
        const list = this.getPatternAssetList(kind)
        return list?.find(v => v.id === assetId)
    }

    getColorPlaceholders(patternKind: PatternsKind, assetId: string): (ColorPlaceholder[]) {
        const asset = this.getPatternAsset(patternKind, assetId)
        return asset!.patterns.filter(v => v.colorPlaceholder != null).map(v => v.colorPlaceholder!)
    }

    getColor(id: string): (ColorAsset | undefined) {
        return this.colors.find(v => v.id === id)
    }

    getAssetLabel(assetKind: PatternsKind, id: string): (string | undefined) {
        return this.getPatternAsset(assetKind, id)?.label
    }

    getFontSampleJpStringCharacters(): string {
        const o: { [key in string]: boolean } = {}
        for (let i = 0; i < this.fonts.length; i++) {
            const font = this.fonts[i]
            const s = font.getSample('jp')
            for (let j = 0; j < s.length; j++) {
                o[s[j]] = true
            }
        }
        return Object.keys(o).sort().join('')
    }
}

interface ConstructorArg {
    readonly manImage: AssetImage
    readonly refImage: AssetImage
    readonly shadowImage: AssetImage
    readonly innerShirtImage: AssetImage
    readonly shirtLogoImages: {
        [key in ManType]: {
            [key in ShirtLogo]: string
        }
    }
    readonly pantsLogoImages: {
        [key in ManType]: {
            [key in PantsLogo]: string
        }
    }
    readonly patternsAssets: {
        [key in PatternsAssetKind]: PatternsAsset[]
    }
    readonly colors: ColorAsset[]
    readonly fonts: Font[]
}

const fontSampleTypes = ['default', 'number', 'jp'] as const

export type FontSampleType = typeof fontSampleTypes[number]

export class Font {
    readonly id: string
    readonly family: string
    readonly url: string | undefined
    readonly weight: string | undefined
    readonly style: string | undefined
    readonly tags: string[]
    readonly sample?: {
        [key in FontSampleType]: string
    }
    readonly sizeBias?: number
    constructor(data: FontData) {
        this.id = data.id
        this.family = data.family
        this.url = data.url
        this.weight = data.weight
        this.style = data.style
        this.tags = data.tags
        this.sample = data.sample
        this.sizeBias = data.sizeBias
    }

    getSample(t: FontSampleType): string {
        if (this.sample == null) {
            return Assets.DEFAULT_FONT_SAMPLE
        }
        return this.sample[t]
    }
}

interface FontData {
    readonly id: string,
    readonly family: string,
    readonly url?: string,
    readonly weight?: string,
    readonly style?: string,
    readonly tags: string[],
    readonly sample?: {
        [key in FontSampleType]: string
    },
    readonly sizeBias?: number
}

export type AssetImage = {
    [key in Angle]?: {
        [key in ManType]?: string
    }
}

export interface Pattern {
    readonly image: AssetImage
    readonly colorPlaceholder?: ColorPlaceholder | undefined
    readonly nestedPatterns?: NestedPatterns | undefined
}

export interface NestedPatterns {
    patternsKind: PatternsAssetKind
    placement: NestedPatternPlacement
    colorPlaceholders: ColorPlaceholder[]
}

export interface InnerShirtAsset extends PatternsAsset {
    readonly none?: boolean | undefined
}

export interface SelectableAsset {
    id: string
    label: string
    thumb: string
}

export interface PatternsAsset extends SelectableAsset {
    readonly patterns: Pattern[]
    readonly maskImage?: AssetImage
    readonly refImage?: AssetImage
    readonly shadowImage?: AssetImage
}

export class ColorAsset {
    constructor(
        readonly id: string,
        readonly label: string,
        readonly rgb: RGBColor,
        readonly tags: string[]) {}
}

export const BLACK = { r: 0, g: 0, b: 0, a: 1.0 }
export const BLUE = { r: 0, g: 0, b: 255, a: 1.0 }

export interface RGBColor {
    readonly r: number
    readonly g: number
    readonly b: number
}

export interface AssetsServer {
    getAssets(): Promise<Assets>
}
