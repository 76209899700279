import { ComponentType, createContext, PropsWithChildren, useContext } from "react";
import { Server } from "../../types/server";

export const ServerProvider:ComponentType<Props> = ({server, children}) => {
    const value:ContextValue = {server}
    return <context.Provider value={value}>{children}</context.Provider>
}

export const useServer = () => {
    return useContext(context)!
}

type Props = PropsWithChildren<{
    server:Server
}>

interface ContextValue {
    server:Server
}

const context = createContext<ContextValue|null>(null)
