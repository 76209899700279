import { MarkingPlacement, PantsNumberPlacement, PersonalNamePlacement, ShirtBackNumberPlacement, ShirtFrontNumberPlacement, SocksPrintPlacement } from "../../types/design"
import { Angle, ManType } from "../../types/editor"
import { ImageLayout, Point, Size, TextLayout } from "./types"

type Config = {
    debug: boolean,
    enableCropText: boolean,
    pos: {
        [key in ManType]: {
            [key in Angle]?: {
                socksPrint?: {
                    [key in SocksPrintPlacement]?: {
                        [key in 'L' | 'R']: SocksPrintConfig
                    }
                },
                shirtFrontNumber?: {
                    [key in ShirtFrontNumberPlacement]?: NumberConfig
                },
                shirtBackNumber?: {
                    [key in ShirtBackNumberPlacement]?: NumberConfig
                },
                pantsNumber?: {
                    [key in PantsNumberPlacement]?: NumberConfig
                },
                personalName?: {
                    [key in PersonalNamePlacement]?: PersonalNameConfig
                },
                markingText?: {
                    [key in MarkingPlacement]?: MarkingTextConfig
                },
                markingImage?: {
                    [key in MarkingPlacement]?: MarkingImageConfig
                }
            }
        }
    }
}

export const config: Config = {
    debug: false,
    enableCropText: true,
    pos: {
        man01: {
            front: {
                socksPrint: {
                    socksPrint1: {
                        L: { x: 43, y: 751, w: 50, h: 20, fontSize: 10, layout: { hAlign: 'center', vAlign: 'center', rotate: 0.2 } },
                        R: { x: 283, y: 751, w: 50, h: 20, fontSize: 10, layout: { hAlign: 'center', vAlign: 'center', rotate: -0.2 } },
                    },
                },
                shirtFrontNumber: {
                    center: { x: 168, y: 195, w: 37, h: 37, fontSize: 50, layout: { hAlign: 'center', vAlign: 'center' } },
                    hara: { x: 160, y: 308, w: 52, h: 52, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center' } },
                    left: { x: 215, y: 210, w: 52, h: 52, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center' } },
                    right: { x: 105, y: 210, w: 52, h: 52, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                pantsNumber: {
                    left: { x: 245, y: 553, w: 60, h: 60, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center', rotate: -0.05 } },
                    right: { x: 65, y: 553, w: 60, h: 60, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center', rotate: 0.1 } },
                },
                markingText: {
                    shirtFront01: { x: 107, y: 180, w: 200, h: 120, fontSize: 10, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    shirtFront02: { x: 221, y: 180, w: 200, h: 120, fontSize: 10, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingImage: {
                    shirtFront01: { x: 199, y: 205, w: 120, h: 80, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center' } },
                    shirtFront02: { x: 403, y: 205, w: 120, h: 80, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center' } },
                },
            },
            back: {
                socksPrint: {
                    socksPrint2: {
                        L: { x: 50, y: 791, w: 50, h: 20, fontSize: 10, layout: { hAlign: 'spacing', vAlign: 'center', rotate: 0.20 } },
                        R: { x: 291, y: 793, w: 50, h: 20, fontSize: 10, layout: { hAlign: 'spacing', vAlign: 'center', rotate: -0.20 } },
                    },
                },
                shirtBackNumber: {
                    back: { x: 122, y: 160, w: 140, h: 240, fontSize: 240, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                personalName: {
                    top: { x: 126, y: 166, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    topArch: { x: 126, y: 166, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center', archR: 300 } },
                    bottom: { x: 123, y: 345, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingText: {
                    shirtBack01: { x: 174, y: 154, w: 40, h: 12, fontSize: 12, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    shirtBack02: { x: 129, y: 168, w: 134, h: 22, fontSize: 22, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingImage: {
                    shirtBack01: { x: 255, y: 145, w: 200, h: 70, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center' } },
                    shirtBack02: { x: 230, y: 220, w: 250, h: 105, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center' } },
                },
            },
            hira: {
                shirtFrontNumber: {
                    left: { x: 120, y: 180, w: 52, h: 52, fontSize: 55, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                pantsNumber: {
                    left: { x: 125, y: 653, w: 60, h: 60, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center', rotate: -0.05 } },
                },
                shirtBackNumber: {
                    back: { x: 233, y: 160, w: 140, h: 225, fontSize: 210, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                personalName: {
                    top: { x: 240, y: 166, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    topArch: { x: 240, y: 166, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center', archR: 300 } },
                    bottom: { x: 240, y: 320, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingText: {
                    shirtFront02: { x: 123, y: 160, w: 45, h: 10, fontSize: 10, layout: { hAlign: 'spacing', vAlign: 'center', rotate: 0.1 } },
                    shirtFront05: { x: 124, y: 189, w: 36, h: 36, fontSize: 36, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingImage: {
                    shirtFront02: { x: 175, y: 200, w: 120, h: 80, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center', rotate: 0.1 } },
                    shirtBack02: { x: 485, y: 220, w: 250, h: 105, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center', rotate: -0.05 } },
               },
            },
        },
        man02: {
            front: {
                socksPrint: {
                    socksPrint1: {
                        L: { x: 112, y: 794, w: 35, h: 14, fontSize: 7, layout: { hAlign: 'center', vAlign: 'center', rotate: 0.1 } },
                        R: { x: 251, y: 795, w: 35, h: 14, fontSize: 7, layout: { hAlign: 'center', vAlign: 'center', rotate: -0.15 } },
                    },
                },
                shirtFrontNumber: {
                    center: { x: 185, y: 365, w: 26, h: 26, fontSize: 35, layout: { hAlign: 'center', vAlign: 'center' } },
                    hara: { x: 180, y: 459, w: 38.5, h: 38.5, fontSize: 38.5, layout: { hAlign: 'center', vAlign: 'center' } },
                    left: { x: 220, y: 378, w: 38.5, h: 38.5, fontSize: 38.5, layout: { hAlign: 'center', vAlign: 'center' } },
                    right: { x: 137, y: 378, w: 38.5, h: 38.5, fontSize: 38.5, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                pantsNumber: {
                    left: { x: 246, y: 638, w: 42, h: 42, fontSize: 35, layout: { hAlign: 'center', vAlign: 'center', rotate: -0.1 } },
                    right: { x: 114, y: 640, w: 42, h: 42, fontSize: 35, layout: { hAlign: 'center', vAlign: 'center', rotate: 0.12 } },
                },
                markingText: {
                    shirtFront01: { x: 139, y: 353, w: 34, h: 7, fontSize: 7, layout: { hAlign: 'spacing', vAlign: 'center', rotate: -0.1 } },
                    shirtFront02: { x: 225, y: 353, w: 34, h: 7, fontSize: 7, layout: { hAlign: 'spacing', vAlign: 'center', rotate: 0.1 } },
                },

                markingImage: {
                    shirtFront01: { x: 139, y: 353, w: 34, h: 7, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center', rotate: -0.05 } },
                    shirtFront02: { x: 225, y: 353, w: 34, h: 7, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center', rotate: 0.05 } },
                },
            },
            back: {
                socksPrint: {
                    socksPrint2: {
                        L: { x: 106, y: 810, w: 35, h: 14, fontSize: 7, layout: { hAlign: 'center', vAlign: 'center', rotate: 0.2 } },
                        R: { x: 249, y: 809, w: 35, h: 14, fontSize: 7, layout: { hAlign: 'center', vAlign: 'center', rotate: -0.2 } },
                    },
                },
                shirtBackNumber: {
                    back: { x: 137, y: 352, w: 118, h: 170, fontSize: 170, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                personalName: {
                    top: { x: 137, y: 339, w: 119, h: 22, fontSize: 22, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    topArch: { x: 137, y: 339, w: 119, h: 22, fontSize: 22, layout: { hAlign: 'spacing', vAlign: 'center', archR: 300 } },
                    bottom: { x: 137, y: 490, w: 118, h: 18, fontSize: 18, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingText: {
                    shirtBack01: { x: 183, y: 328, w: 28, h: 11, fontSize: 11, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    shirtBack02: { x: 140, y: 340, w: 113, h: 20, fontSize: 20, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingImage: {
                    shirtBack01: { x: 183, y: 328, w: 28, h: 11, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center' } },
                    shirtBack02: { x: 140, y: 340, w: 113, h: 20, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center' } },
                },
            },
            hira: {
                shirtFrontNumber: {
                    right: { x: 210, y: 180, w: 52, h: 52, fontSize: 55, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                pantsNumber: {
                    right: { x: 190, y: 653, w: 60, h: 60, fontSize: 60, layout: { hAlign: 'center', vAlign: 'center', rotate: 0.1 } },
                },
                shirtBackNumber: {
                    back: { x: 15, y: 160, w: 140, h: 240, fontSize: 240, layout: { hAlign: 'center', vAlign: 'center' } },
                },
                personalName: {
                    top: { x: 3, y: 166, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center' } },
                    topArch: { x: 3, y: 166, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center', archR: 300 } },
                    bottom: { x: 20, y: 325, w: 139, h: 25, fontSize: 25, layout: { hAlign: 'spacing', vAlign: 'center' } },
                },
                markingText: {
                    shirtFront01: { x: 212, y: 160, w: 45, h: 10, fontSize: 10, layout: { hAlign: 'spacing', vAlign: 'center', rotate: -0.1 } },
                },
                markingImage: {
                    shirtFront01: { x: 405, y: 200, w: 120, h: 80, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center', rotate: -0.1 } },
                    shirtBack02: { x: -35, y: 220, w: 250, h: 105, layout: { adjust: 'inner', hAlign: 'center', vAlign: 'center', rotate: 0.05 } },
                },
            },
        }
    }
}

interface SocksPrintConfig extends Point, Size {
    fontSize: number,
    layout: TextLayout,
}

interface NumberConfig extends Point, Size {
    fontSize: number,
    layout: TextLayout,
}

interface PersonalNameConfig extends Point, Size {
    fontSize: number,
    layout: TextLayout,
}

interface MarkingImageConfig extends Point, Size {
    layout: ImageLayout,
}

interface MarkingTextConfig extends Point, Size {
    fontSize: number,
    layout: TextLayout,
}

