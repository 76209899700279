import { FC, useEffect } from "react"
import { ASSETS_VERSION } from "../../config"
import { useAssets } from "../providers/assets"

export const AssetsLoader: FC = ({ children }) => {
    const { loaded, loadAssets } = useAssets()
    useEffect(() => {
        loadAssets(ASSETS_VERSION)
    }, [loadAssets])
    if (!loaded) {
        return <></>
    }
    return <>{children}</>
}

