import BlendLayer from "./BlendLayer";
import { Canvas } from "./Canvas";
import ClearLayer from "./ClearLayer";
import ImageLayer from "./ImageLayer";
import TintLayer from "./TintLayer";

import { ComponentType, useMemo } from "react";
import TextLayer from "./TextLayer";
import { Border, ImageLayout, Rect, TextLayout } from "./types";
import { Design, MarkingPlacement, markingPlacements, PatternsKind } from "../../types/design";
import { Assets, NestedPatterns, Pattern } from "../../types/asset";
import { Angle, ManType } from "../../types/editor";
import { Layer, NullLayer } from "./Layer";
import { config } from './config'
import MarkingImageLayer from "./MarkingImageLayer";
import FBlendLayer from "./FBlendLayer";
import { Server } from "../../types/server";

const WIDTH = 700
const HEIGHT = 1000

const canvasRect = new Rect(0, 0, WIDTH, HEIGHT)

const defaultImageLayout: ImageLayout = {
    adjust: "inner",
    hAlign: 'center',
    vAlign: 'center',
}

const defaultTextLayout: TextLayout = {
    // hAlign: 'spacing',
    hAlign: 'spacing',
    vAlign: 'center',
}

const Preview: ComponentType<Props> = ({ design, manType, angle, assets, markingImageServer, className, onDraw, onDrawStart, preRender }) => {
    const layer = useMemo(() => {
        const getPatternAsset = (kind: PatternsKind) => assets.getPatternAsset(kind, design.getPatternAssetId(kind))
        const shirtAsset = getPatternAsset('shirt')!
        const eriAsset = getPatternAsset('eri')!
        const pantsAsset = getPatternAsset('pants')!
        const socksAsset = getPatternAsset('socks')!
        const manLayer = () => {
            if (angle === 'hira') {
                return NullLayer
            }
            return new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, assets.manImage[angle]![manType]!)
        }
        const shadowLayer = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, assets.shadowImage[angle]![manType]!)
        const refLayer = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, assets.refImage[angle]![manType]!)

        const nestedPatternsLayer = (origPat: Pattern) => {
            const nestedPatterns = origPat.nestedPatterns
            if (nestedPatterns == null) {
                console.log('oops', { origPat })
                return NullLayer
            }
            const origImgLayer = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, origPat.image[angle]![manType]!)
            const assetId = design.nestedPatternAssetIds[nestedPatterns.placement]
            const asset = assets.getPatternAsset(nestedPatterns.patternsKind, assetId)
            console.log({ nestedPatterns, assetId, asset, design })

            const base = new BlendLayer(WIDTH, HEIGHT, [
                ...asset!.patterns.map((pat, i) => {
                    const colorPlaceholder = nestedPatterns.colorPlaceholders[i]
                    if (colorPlaceholder == null) {
                        return NullLayer
                    }
                    const imgLayer = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, pat.image[angle]![manType]!)
                    const colorId = design.colors[colorPlaceholder]
                    const color = assets.getColor(colorId)!
                    console.log({ nestedPatterns, assetId, asset, design, colorPlaceholder, colorId, color })
                    return new TintLayer(imgLayer, color.rgb)
                }),
            ])

            return new FBlendLayer(WIDTH, HEIGHT, origImgLayer, base, 'source-in')
        }

        const shirtLayer = () => {
            const base = new BlendLayer(WIDTH, HEIGHT, [
                ...shirtAsset!.patterns.map(pat => {
                    const imgLayer = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, pat.image[angle]![manType]!)
                    if (pat.colorPlaceholder == null) {
                        return nestedPatternsLayer(pat)
                    } else {
                        const colorId = design.colors[pat.colorPlaceholder]
                        const color = assets.getColor(colorId)!
                        return new TintLayer(imgLayer, color.rgb)
                    }
                }),
            ])
            const blend1 = new FBlendLayer(WIDTH, HEIGHT, eriMaskLayer, base, 'source-out')
            return blend1
        }

        const eriMaskLayer = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, eriAsset!.maskImage![angle]![manType]!)

        const pantsLayer = new BlendLayer(WIDTH, HEIGHT, pantsAsset!.patterns.map(pat => {
            if (pat.colorPlaceholder == null) {
                return NullLayer
            }
            const colorId = design.colors[pat.colorPlaceholder]
            const color = assets.getColor(colorId)!
            return new TintLayer(new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, pat.image[angle]![manType]!), color.rgb)
        }))
        const eriLayer = () => {
            const base = new BlendLayer(WIDTH, HEIGHT, eriAsset!.patterns.map(pat => {
                if (pat.colorPlaceholder == null) {
                    return NullLayer
                }
                const colorId = design.colors[pat.colorPlaceholder]
                const color = assets.getColor(colorId)!
                return new TintLayer(new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, pat.image[angle]![manType]!), color.rgb)
            }))
            const refImg = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, eriAsset!.refImage![angle]![manType]!)
            const shadowImg = new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, eriAsset!.shadowImage![angle]![manType]!)
            const ref = new FBlendLayer(WIDTH, HEIGHT, base, refImg, 'source-in')
            const shadow = new FBlendLayer(WIDTH, HEIGHT, base, shadowImg, 'source-in')
            const l1 = new FBlendLayer(WIDTH, HEIGHT, base, shadow, 'multiply')
            const l2 = new FBlendLayer(WIDTH, HEIGHT, l1, ref, 'lighten')
            return l2
        }
        const innerShirtLayer = () => {
            if (angle === 'hira') {
                return NullLayer
            }
            if (design.innerShirtType === 'none') {
                return NullLayer
            }
            const mainColor = assets.getColor(design.colors['innerShirtMain'])!
            return new TintLayer(new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, assets.innerShirtImage[angle]![manType]!), mainColor.rgb)
        }
        const socksLayer = new BlendLayer(WIDTH, HEIGHT, socksAsset!.patterns.map(pat => {
            if (angle === 'hira') {
                return NullLayer
            }
            if (pat.colorPlaceholder == null) {
                return NullLayer
            }
            const colorId = design.colors[pat.colorPlaceholder]
            const color = assets.getColor(colorId)!
            return new TintLayer(new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, pat.image[angle]![manType]!), color.rgb)
        }))

        const getShirtNumberBorders = (borderWidth: number): Border[] => {
            const colorPlaceholders = design.getShirtNumberBorderColorPlaceholders()
            const borders: Border[] = []
            for (let i = 0; i < colorPlaceholders.length; i++) {
                const colorPlaceholder = colorPlaceholders[i]
                const colorId = design.colors[colorPlaceholder]
                const color = assets.getColor(colorId)!
                borders.push({ borderWidth, color: color.rgb })
            }
            return borders
        }

        const shirtFrontNumberLayer = (): Layer => {
            const c1 = config.pos[manType][angle]?.shirtFrontNumber
            if (c1 == null) {
                return NullLayer
            }
            const conf = c1[design.shirtFrontNumberPlacement]
            if (conf == null) {
                return NullLayer
            }
            const colorId = design.colors.shirtNumberFront
            const color = assets.getColor(colorId)
            const font = assets.fonts.find(v => v.id === design.fontIds.number)!
            let fontSize = conf.fontSize
            if (font.sizeBias != null) {
                fontSize *= font.sizeBias
            }
            const textLayer = new TextLayer(
                WIDTH, HEIGHT,
                design.texts.number,
                new Rect(conf.x, conf.y, conf.w, conf.h),
                conf.layout,
                color!.rgb,
                fontSize,
                font.family,
                getShirtNumberBorders(fontSize * 0.08),
                config.debug,
            )
            if (!config.enableCropText) {
                return textLayer
            }
            const mask = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, shirtAsset.patterns[0]!.image[angle]![manType]!,
            )
            return new FBlendLayer(WIDTH, HEIGHT, mask, textLayer, 'source-in')
        }

        const shirtBackNumberLayer = (): Layer => {
            const c1 = config.pos[manType][angle]?.shirtBackNumber
            if (c1 == null) {
                return NullLayer
            }
            const conf = c1[design.shirtBackNumberPlacement]
            if (conf == null) {
                return NullLayer
            }
            const colorId = design.colors.shirtNumberBack
            const color = assets.getColor(colorId)
            const font = assets.fonts.find(v => v.id === design.fontIds.number)!
            let fontSize = conf.fontSize
            if (font.sizeBias != null) {
                fontSize *= font.sizeBias
            }
            const textLayer = new TextLayer(
                WIDTH, HEIGHT,
                design.texts.number,
                new Rect(conf.x, conf.y, conf.w, conf.h),
                conf.layout,
                color!.rgb,
                fontSize,
                font.family,
                getShirtNumberBorders(fontSize * 0.03),
                config.debug,
            )
            if (!config.enableCropText) {
                return textLayer
            }
            const mask = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, shirtAsset.patterns[0]!.image[angle]![manType]!,
            )
            return new FBlendLayer(WIDTH, HEIGHT, mask, textLayer, 'source-in')
        }

        const getPantsNumberBorders = (borderWidth: number): Border[] => {
            const colorPlaceholders = design.getPantsNumberBorderColorPlaceholders()
            const borders: Border[] = []
            for (let i = 0; i < colorPlaceholders.length; i++) {
                const colorPlaceholder = colorPlaceholders[i]
                const colorId = design.colors[colorPlaceholder]
                const color = assets.getColor(colorId)!
                borders.push({ borderWidth, color: color.rgb })
            }
            return borders
        }

        const pantsNumberLayer = (): Layer => {
            const c1 = config.pos[manType][angle]?.pantsNumber
            if (c1 == null) {
                return NullLayer
            }
            const conf = c1[design.pantsNumberPlacement]
            if (conf == null) {
                return NullLayer
            }
            const colorId = design.colors.pantsNumber
            const color = assets.getColor(colorId)
            const font = assets.fonts.find(v => v.id === design.fontIds.number)!
            let fontSize = conf.fontSize
            if (font.sizeBias != null) {
                fontSize *= font.sizeBias
            }
            const textLayer = new TextLayer(
                WIDTH, HEIGHT,
                design.texts.number,
                new Rect(conf.x, conf.y, conf.w, conf.h),
                conf.layout,
                color!.rgb,
                fontSize,
                font.family,
                getPantsNumberBorders(fontSize * 0.1),
                config.debug,
            )
            if (!config.enableCropText) {
                return textLayer
            }
            const mask = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, pantsAsset.patterns[0]!.image[angle]![manType]!,
            )
            return new FBlendLayer(WIDTH, HEIGHT, mask, textLayer, 'source-in')
        }

        const personalNameLayer = (): Layer => {
            const c1 = config.pos[manType][angle]?.personalName
            if (c1 == null) {
                return NullLayer
            }
            const conf = c1[design.personalNamePlacement]
            if (conf == null) {
                return NullLayer
            }
            const font = assets.fonts.find(v => v.id === design.fontIds.personalName)!
            let fontSize = conf.fontSize
            if (font.sizeBias != null) {
                fontSize *= font.sizeBias
            }
            const colorId = design.colors.personalName
            const color = assets.getColor(colorId)!
            const rect = new Rect(conf.x, conf.y, conf.w, conf.h)
            const textLayer = new TextLayer(
                WIDTH, HEIGHT,
                design.texts.personalName,
                rect,
                conf.layout,
                color.rgb,
                fontSize,
                font.family,
                [],
                config.debug,
            )
            if (!config.enableCropText) {
                return textLayer
            }
            const mask = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, shirtAsset.patterns[0]!.image[angle]![manType]!,
            )
            return new FBlendLayer(WIDTH, HEIGHT, mask, textLayer, 'source-in')
        }

        const socksPrintLayer = (): Layer => {
            if (angle === 'hira') {
                return NullLayer
            }
            const create = (lr: 'L' | 'R') => {
                const c1 = config.pos[manType][angle]?.socksPrint
                if (c1 == null) {
                    return NullLayer
                }
                const c2 = c1[design.socksPrintPlacement]
                if (c2 == null) {
                    return NullLayer
                }
                const conf = c2[lr]
                if (conf == null) {
                    return NullLayer
                }
                switch (design.socksPrintPlacement) {
                    case 'none':
                        return NullLayer
                    case 'socksPrint1':
                        if (angle !== 'front') {
                            return NullLayer
                        }
                        break
                    case 'socksPrint2':
                        if (angle !== 'back') {
                            return NullLayer
                        }
                        break
                }
                const font = assets.fonts.find(v => v.id === design.fontIds.socksName)!
                let fontSize = conf.fontSize
                if (font.sizeBias != null) {
                    fontSize *= font.sizeBias
                }
                const colorId = design.colors.socksPrint
                const color = assets.getColor(colorId)!
                const rect = new Rect(conf.x, conf.y, conf.w, conf.h)
                const textLayer = new TextLayer(
                    WIDTH, HEIGHT,
                    design.texts.socksName,
                    rect,
                    conf.layout,
                    color.rgb,
                    fontSize,
                    font.family,
                    [],
                    config.debug,
                )
                return textLayer
            }
            const l = create('L')
            const r = create('R')
            const textLayer = new BlendLayer(WIDTH, HEIGHT, [l, r])

            if (!config.enableCropText) {
                return textLayer
            }
            const socksAsset = getPatternAsset('socks')!
            const mask = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, socksAsset.patterns[0]!.image[angle]![manType]!,
            )
            return new FBlendLayer(WIDTH, HEIGHT, mask, textLayer, 'source-in')
        }

        const shirtLogoLayer = () => {
            if (angle === 'hira') {
                return NullLayer
            }
            if (angle !== 'front') {
                return NullLayer
            }
            let color: { r: number, g: number, b: number } = { r: 0, g: 0, b: 0 }
            switch (design.shirtLogo) {
                case "centerBlack":
                case "leftBlack":
                case "rightBlack":
                    color = { r: 0, g: 0, b: 0 }
                    break
                case "centerWhite":
                case "leftWhite":
                case "rightWhite":
                    color = { r: 255, g: 255, b: 255 }
                    break
            }
            return new TintLayer(new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, assets.shirtLogoImages[manType][design.shirtLogo]), color)
        }

        const pantsLogoLayer = () => {
            if (angle === 'hira') {
                return NullLayer
            }
            let color: { r: number, g: number, b: number } = { r: 0, g: 0, b: 0 }

            switch (design.pantsLogo) {
                case 'leftBackBlack':
                case 'rightBackBlack':
                case 'leftBackWhite':
                case 'rightBackWhite':
                    if (angle !== 'back') {
                        return NullLayer
                    }
                    break
                case 'leftFrontBlack':
                case 'rightFrontBlack':
                case 'leftFrontWhite':
                case 'rightFrontWhite':
                    if (angle !== 'front') {
                        return NullLayer
                    }
                    break

            }

            switch (design.pantsLogo) {
                case 'leftBackBlack':
                case 'leftFrontBlack':
                case 'rightBackBlack':
                case 'rightFrontBlack':
                    color = { r: 0, g: 0, b: 0 }
                    break
                case 'leftBackWhite':
                case 'leftFrontWhite':
                case 'rightBackWhite':
                case 'rightFrontWhite':
                    color = { r: 255, g: 255, b: 255 }
                    break
            }
            return new TintLayer(new ImageLayer(WIDTH, HEIGHT, canvasRect, defaultImageLayout, assets.pantsLogoImages[manType][design.pantsLogo]), color)
        }

        const markingLayer = (placement: MarkingPlacement): Layer => {
            const marking = design.markings[placement]
            if (marking == null) {
                return NullLayer
            }
            const markingTextLayer = (): Layer => {
                const c = config.pos[manType][angle]?.markingText
                if (c == null) {
                    return NullLayer
                }
                const conf = c[placement]
                if (conf == null) {
                    return NullLayer
                }
                const colorId = marking.text!.colorId
                const color = assets.getColor(colorId)!
                const fontId = marking.text!.fontId
                const font = assets.fonts.find(v => v.id === fontId)!
                const rect = new Rect(conf.x, conf.y, conf.w, conf.h)
                let fontSize = conf.fontSize
                if (font.sizeBias != null) {
                    fontSize *= font.sizeBias
                }
                return new TextLayer(WIDTH, HEIGHT, marking.text!.text, rect, conf.layout, color.rgb, fontSize, font.family, [], config.debug)
            }
            const markingImageLayer = (): Layer => {
                const c = config.pos[manType][angle]?.markingImage
                if (c == null) {
                    return NullLayer
                }
                const conf = c[placement]
                if (conf == null) {
                    return NullLayer
                }
                const rect = new Rect(conf.x, conf.y, conf.w, conf.h)
                return new MarkingImageLayer(WIDTH, HEIGHT, rect, conf.layout, marking.imageId!, markingImageServer)
            }
            let markingLayer: Layer
            switch (marking.type) {
                case 'text':
                    markingLayer = markingTextLayer()
                    break
                case 'image':
                    markingLayer = markingImageLayer()
                    break
            }
            if (markingLayer === NullLayer) {
                return markingLayer
            }
            if (!config.enableCropText) {
                return markingLayer
            }

            const mask1 = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, shirtAsset.patterns[0]!.image[angle]![manType]!,
            )
            const mask2 = new ImageLayer(WIDTH, HEIGHT,
                canvasRect, defaultImageLayout, pantsAsset.patterns[0]!.image[angle]![manType]!,
            )
            const mask = new BlendLayer(WIDTH, HEIGHT, [mask1, mask2])
            return new FBlendLayer(WIDTH, HEIGHT, mask, markingLayer, 'source-in')
        }

        const blendLayer1 = new BlendLayer(WIDTH, HEIGHT, [
            innerShirtLayer(),
            shirtLayer(),
            pantsLayer,
            socksLayer,
            shirtLogoLayer(),
            pantsLogoLayer(),
            socksPrintLayer(),
            shirtFrontNumberLayer(),
            shirtBackNumberLayer(),
            pantsNumberLayer(),
            personalNameLayer(),
            ...markingPlacements.map(pl => markingLayer(pl))
        ])

        const refLayer2 = new FBlendLayer(WIDTH, HEIGHT, blendLayer1, refLayer, 'source-in')
        const shadowLayer2 = new FBlendLayer(WIDTH, HEIGHT, blendLayer1, shadowLayer, 'source-in')
        const blendLayer2 = new FBlendLayer(WIDTH, HEIGHT, blendLayer1, shadowLayer2, 'multiply')
        const blendLayer3 = new FBlendLayer(WIDTH, HEIGHT, blendLayer2, refLayer2, 'lighten')
        const blendLayer4 = new BlendLayer(WIDTH, HEIGHT, [manLayer(), blendLayer3, eriLayer()])

        return new ClearLayer(blendLayer4)
    }, [angle, assets, design, manType, markingImageServer])
    return (
        <Canvas
            className={className}
            layer={layer}
            onDraw={onDraw}
            onDrawStart={onDrawStart}
            preRender={preRender}
        />
    )
}

export default Preview

interface Props {
    design: Design,
    assets: Assets,
    angle: Angle,
    manType: ManType,
    markingImageServer: Server,
    className: string
    onDrawStart?: () => any,
    onDraw?: (canvas: HTMLCanvasElement) => any,
    preRender: () => Promise<any>,
}
