import { ChangeEvent, ComponentType, DragEvent, useRef, useState } from "react"
import { useEditor } from "./providers/editor"
import { MarkingImage } from "../types/editor"

const MarkingImageUpload: ComponentType<Props> = ({ onUpload }) => {
    const { uploadMarkingImage } = useEditor()

    const [isDrag, setIsDrag] = useState(false)

    const refInput = useRef<HTMLInputElement | null>(null)

    const upload = async (file: File) => {
        const markingImage = await uploadMarkingImage(file)
        onUpload(markingImage)
    }

    const handleChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        if (evt.target.files == null) {
            return
        }
        if (evt.target.files.length === 0) {
            return
        }
        const file = evt.target.files[0]
        await upload(file)
    }

    const handleDrop = async (evt: DragEvent) => {
        evt.preventDefault()
        setIsDrag(false)
        const files = evt.dataTransfer.files
        if (files.length === 0) {
            return false
        }
        const file = files[0]
        await upload(file)
    }

    const handleDragEnter = async (evt: DragEvent) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsDrag(true)
    }

    const handleDragOver = async (evt: DragEvent) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsDrag(true)
    }

    const handleDragLeave = async (evt: DragEvent) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsDrag(false)
    }

    const className = () => {
        const a = []
        if (isDrag) {
            a.push('drag')
        }
        return a.join(' ')
    }

    return (
        <>
            <div id="upFileWrap" className={className()}>
                <div className="uploadBox sN">
                    <div className="text">
                        <span className="big">{className()}ここにファイルをドロップしてください</span><span className="small">または</span>
                    </div>
                    <div className="text">
                        <span className="big">ここをタップしてファイルを選択</span>
                    </div>
                </div>
                <div className="uploadBox pN">
                    <div className="text">
                        <span className="big">ここをタップしてファイルを選択</span>
                    </div>
                </div>
                <div className="lineBox"
                    onClick={() => refInput.current!.click()}
                    onDrop={handleDrop}
                    onDragEnterCapture={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeaveCapture={handleDragLeave}
                ></div>
                <div id="inputFileWrap" style={{display:'none'}}>
                    <input className="markingUploadInput" id="btnInputFile" type="file" onChange={handleChange} ref={refInput} />
                </div>
            </div>
        </>
    )
}

export default MarkingImageUpload

interface Props {
    onUpload: (result: MarkingImage) => any
}
