import { Design, serializeDesign } from "../types/design";
import { DesignShareInfo, MarkingImage, Snapshot } from "../types/editor";
import { Order } from "../types/order";
import { Server } from "../types/server";
import { Session } from "../types/session";

export class ApiServer implements Server {
    private readonly baseUrl

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }



    async login(email: string, password: string): Promise<void> {
        const resp = await fetch(`${this.baseUrl}/api/order/sessions`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
            }),
        })
        if (!resp.ok) {
            throw Error("register error")
        }
    }

    async logout(): Promise<void> {
        throw Error("not implemented")
    }

    async register(email: string, password: string): Promise<void> {
        const resp = await fetch(`${this.baseUrl}/api/order/users`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
            }),
        })
        if (!resp.ok) {
            throw Error("register error")
        }
    }

    async getCurrentSession(): Promise<Session> {
        const resp = await fetch(`${this.baseUrl}/api/order/sessions/current`)
        if (!resp.ok) {
            throw Error("getCurrentSession error")
        }
        const ret = (await resp.json()) as any
        const id = ret.id as string
        const userName = ret.userName as string
        return {
            userId: id,
            userName: userName,
        }
    }

    async uploadMarkingImage(file: File): Promise<MarkingImage> {
        const resp = await fetch(`${this.baseUrl}/api/order/media/upload`, {
            method: 'POST',
            headers: {
                "Content-Type": 'image/png',
            },
            body: file,
        })
        if (!resp.ok) {
            throw new Error("saveDesignSnapshotMedia error")
        }
        const id = (await resp.json()).id as string
        console.log({id})
        return await this.getMarkingImage(id)
    }

    async getMarkingImage(mediaId: string): Promise<MarkingImage> {
        const resp = await fetch(`${this.baseUrl}/api/order/media/${mediaId}`)
        if (!resp.ok) {
            throw Error("getMarkingImage error")
        }
        const obj = await resp.json()
        return {
            id: mediaId,
            url: obj.url
        }
    }

    async saveDesign(design: Design): Promise<string> {
        const rawJSON = serializeDesign(design)
        const resp = await fetch(`${this.baseUrl}/api/order/designs`, {
            method: 'POST',
            body: JSON.stringify({
                rawJSON,
            })
        })
        if (!resp.ok) {
            throw Error("save design error")
        }
        return (await resp.json()).editorUrl as string
    }

    async getDesign(id: string): Promise<string> {
        const resp = await fetch(`${this.baseUrl}/api/order/designs/${id}`)
        if (!resp.ok) {
            throw Error("eror getDesign")
        }
        const obj = await resp.json()
        return obj.rawJson
    }

    async addToFavorites(design: Design, snapshots: Snapshot[]): Promise<string> {
        const resp = await fetch(`${this.baseUrl}/api/order/favorites`, {
            method: 'POST',
            body: JSON.stringify({
                rawJson: serializeDesign(design),
                snapshots,
            })
        })
        if (!resp.ok) {
            throw Error("addToFavorite error")
        }
        const id = (await resp.json()).id as string
        return id
    }

    async saveDesignSnapshotMedia(file: Blob): Promise<string> {
        const resp = await fetch(`${this.baseUrl}/api/order/media/upload`, {
            method: 'POST',
            headers: {
                "Content-Type": 'image/png',
            },
            body: file,
        })
        if (!resp.ok) {
            throw new Error("saveDesignSnapshotMedia error")
        }
        const id = (await resp.json()).id as string
        return id
    }

    async getSnapshotUrl(mediaId: string): Promise<string> {
        const resp = await fetch(`${this.baseUrl}/api/order/media/${mediaId}`)
        if (!resp.ok) {
            throw Error("getSnapshotUrl error")
        }
        return (await resp.json()).url as string
    }

    getDesignShareInfo(designId: string): Promise<DesignShareInfo> {
        throw new Error("Method not implemented.");
    }

    async createOrder(order: Order): Promise<string> {
        const resp = await fetch(`${this.baseUrl}/api/order/orders`, {
            method: 'POST',
            headers: {
                "Content-Type": 'image/png',
            },
            body: JSON.stringify(order),
        })
        if (!resp.ok) {
            throw new Error("createorder error")
        }
        const id = (await resp.json()).id as string
        return id
    }
}
